import React from 'react';
import { Box, useMediaQuery } from '@mui/system';
import headerImg from 'store/images/solutionHeaderIMg.png';
import PlayIcon from 'store/images/play-circle.svg';
import { Button, Typography, useTheme } from '@mui/material';
import { ReactComponent as DashLines } from 'store/images/dash-lines.svg';

const FirstSection = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const handleNavigation = (link) => {
    window.open(link, '_blank');
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: { xs: '128px 0px 64px 0px', md: '128px 0px 96px 0px' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '64px',
          // width: '100%',
        }}
      >
        <Box
          className="mainOuterBox"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: { xs: 'auto', md: '1280px' },
            padding: { xs: '0px 16px', md: '0px 32px' },
            justifyContent: 'center',
            alignItems: 'center',
            gap: '64px',
          }}
        >
          <Box
            className="upperPart"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: { md: '24px', xs: '32px' },
              flex: '1 0 0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                height: { xs: 'auto', md: '400px' },
                alignItems: 'flex-start',
                gap: '48px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: { xs: '16px', md: '24px' },
                  width: { xs: '100%', md: '652px' },
                }}
              >
                <Typography
                  sx={{
                    width: { xs: '100%', md: '631px' },
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: { xs: '36px', md: '60px' },
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: { xs: '44px', md: '72px' },
                    letterSpacing: { xs: '-.72px', md: '-1.2px' },
                  }}
                >
                  Analytics and Provisioning Solutions
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: { sm: '18px', md: '20px' },
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: { sm: '28px', md: '30px' },
                  }}
                >
                  We understand that the backbone of any forward-thinking
                  enterprise lies in its ability to analyze complex data and
                  streamline operations.
                  <br />
                  <br />
                  Our comprehensive analytics and provisioning platforms are
                  designed to elevate your business by harnessing the power of
                  communication scenarios and workflow automation, all built on
                  the robust foundation of Azure technologies.
                </Typography>
              </Box>
              {!matches && (
                <Box
                  sx={{
                    position: 'relative',
                    display: 'flex',
                  }}
                >
                  <img
                    src={headerImg}
                    alt="headerImage"
                    style={{
                      width: '564px',
                      height: '400px',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      borderRadius: '160px 0px 0px 0px',
                      objectFit: 'cover',
                    }}
                  />
                  <DashLines
                    style={{
                      position: 'absolute',
                      right: '-128px',
                      top: '-40px',
                    }}
                  />
                </Box>
              )}
            </Box>
            <Box
              className="buttonPart"
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: '12px',
                width: { xs: '100%', md: 'auto' },
              }}
            >
              <Button
                sx={{
                  display: 'flex',
                  padding: { xs: '12px 20px', md: '16px 28px' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: { xs: '8px', md: '12px' },
                  borderRadius: '8px',
                  border: '1px solid var(--Gray-300, #D0D5DD)',
                  background: 'var(--Base-White, #FFF)',
                  width: 'auto',
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  // width: { xs: '100%' },
                  textTransform: 'none',
                }}
              >
                <img src={PlayIcon} />
                <Typography
                  sx={{
                    color: 'var(--Gray-700, #344054)',
                    fontFamily: 'Inter',
                    fontSize: { xs: '16px', md: '18px' },
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: { xs: '24px', md: '28px' },
                  }}
                >
                  Demo
                </Typography>
              </Button>
              <Button
                onClick={() => handleNavigation('https://azuremarketplace.microsoft.com/en-us/marketplace/apps/ezaixinc1714844874000.teamscoreiqms?tab=Overview')}
                sx={{
                  display: 'flex',
                  padding: { xs: '12px 20px', md: '16px 28px' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: { xs: '8px', md: '12px' },
                  borderRadius: '8px',
                  background: 'var(--Primary-Buttons, #4F9CF9)',
                  width: 'auto',
                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  textTransform: 'none',
                  '&:hover': {
                    background: '#3b7fd1',
                  },
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Base-White, #FFF)',
                    fontFamily: 'Inter',
                    fontSize: { xs: '16px', md: '18px' },
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: { xs: '24px', md: '28px' },
                    whiteSpace: 'nowrap',
                  }}
                >
                  Purchase TeamsCoreIQ
                </Typography>
              </Button>
            </Box>
          </Box>
          {matches && (
            <Box
              sx={{
                position: 'relative',
                display: 'flex',
                width: '100%',
              }}
            >
              <img
                src={headerImg}
                alt="headerImage"
                style={{
                  width: '100%',
                  height: '240px',
                  objectFit: 'cover',
                  display: 'flex',
                  // padding: '0px 0px 32px 394px',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  borderRadius: '64px 0px 0px 0px',
                }}
              />
              <DashLines
              width={194}
              height={265}
                style={{
                  position: 'absolute',
                  right: '-128px',
                  top: '-40px',
                }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default FirstSection;
