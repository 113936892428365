import { Box } from '@mui/material';
import React from 'react';
import Testimonial from 'store/Testimonial';

import testy1 from 'store/images/testimonials/testy1.png';
import testy2 from 'store/images/testimonials/testy2.png';
import testy3 from 'store/images/testimonials/testy3.png';
import testy4 from 'store/images/testimonials/testy4.png';
import testy5 from 'store/images/testimonials/testy5.png';

const EigthSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '48px', md: '95px' },
      }}
    >
      <Testimonial
        headerText="Advanced Technological Integration"
        desc="Leveraging the latest in AI and ML, we go beyond traditional services, offering innovative solutions that redefine the potential of your enterprise systems."
        quote="Simform is quick to identify larger problem with the Software so we decided to expand our scope to build new modules"
        // authorPic={<TestyUser1 />}
        // authorName="Jeewa markram"
        // authorPos="CEO"
        CoverImage={
          <img
            src={testy1}
            alt="testy1"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
            width={403}
          />
        }
      />
      <Testimonial
        headerText="Tailored Solutions for Every Enterprise"
        desc="Recognizing the unique nature of each business, we deliver custom strategies that align with your specific needs, ensuring efficiency, scalability, and satisfaction at every level"
        quote="Simform is quick to identify larger problem with the Software so we decided to expand our scope to build new modules"
        // authorPic={<TestyUser2 />}
        // authorName="Jeewa markram"
        // authorPos="CEO"
        CoverImage={
          <img
            src={testy2}
            alt="testy2"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
            width={403}
          />
        }
        rowReverse={true}
      />
      <Testimonial
        headerText="Dedicated Support and Partnership"
        desc="Our team's expertise is matched only by our commitment to your success. With Ezaix, you gain a partner who is dedicated to maintaining your systems peak performance and supporting your growth."
        // quote="Simform is quick to identify larger problem with the Software so we decided to expand our scope to build new modules"
        // authorPic={<TestyUser3 />}
        // authorName="Jeewa markram"
        // authorPos="CEO"
        CoverImage={
          <img
            src={testy3}
            alt="testy3"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
            width={403}
          />
        }
      />
      <Testimonial
        headerText="Scalable Solutions for Dynamic Growth"
        desc="Designed to adapt and grow with you, our solutions provide the robust foundation necessary for your business to expand and evolve in the fast-paced market."
        // quote="Simform is quick to identify larger problem with the Software so we decided to expand our scope to build new modules"
        // authorPic={<TestyUser2 />}
        // authorName="Jeewa markram"
        // authorPos="CEO"
        CoverImage={
          <img
            src={testy4}
            alt="testy4"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
            width={403}
          />
        }
        rowReverse={true}
      />
      <Testimonial
        headerText="Insightful Analytics for Strategic Decisions"
        desc="Our sophisticated analytics capabilities allow you to leverage data for actionable insights, enhancing customer engagement and facilitating strategic growth."
        // quote="Simform is quick to identify larger problem with the Software so we decided to expand our scope to build new modules"
        // authorPic={<TestyUser3 />}
        // authorName="Jeewa markram"
        // authorPos="CEO"
        CoverImage={
          <img
            src={testy5}
            alt="testy5"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
            width={403}
          />
        }
      />
    </Box>
  );
};

export default EigthSection;
