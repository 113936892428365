import { Box, Button, Typography } from '@mui/material';

function FifthSection() {
  const handleNavigation = (link) => {
    window.location.href = link; 
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          padding: { xs: '0', md: '96px 0px' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'stretch',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: {xs:"100%", md:'1280px'},
            padding: {xs:"0 16px", md:'0px 32px'},
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '32px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              padding: {xs:" 64px 32px", md:'64px'},
              flexDirection: 'column',
              alignItems: 'center',
              gap: '32px',
              alignSelf: 'stretch',
              borderRadius: {xs:"0", md:'16px'},
              background:
                'var(--gradient, linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%))',
            }}
          >
            <Typography
              sx={{
                color: 'var(--Base-White, #FFF)',
                textAlign: 'center',

                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: {xs:"30px", md:'36px'},
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '44px' /* 122.222% */,
                letterSpacing: '-0.72px',
              }}
            >
              Let's Innovate Together!
            </Typography>

            <Typography
              sx={{
                width: {xs:"100%", md:'621px'},
                color: 'var(--Primary-200, #E9D7FE)',
                textAlign: 'center',

                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '30px' /* 122.222% */,
              }}
            >
              Ready to get started? Get in touch today to schedule a call. We
              can discuss all available partner options and get to know your
              business and client needs better.
            </Typography>

            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleNavigation('/contact')}
              sx={{ height: '48px', color: 'white', textTransform: 'none', borderRadius: '8px',
            width: {xs:"100%", md:'fit-content'}
            }}
            >
              <Typography
                sx={{
                  color: 'var(--Base-White, #FFF)',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '24px' ,
                }}
              >
                Become a partner
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FifthSection;

