import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import CustomText from 'store/General/CustomText';

import { FOOTER_INFO, FOOTER_POLICIES, FOOTER_SOCIAL_LINKS } from 'constants/footer';

const Footer = () => {

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const currentYear = new Date().getFullYear();

  const handleNavigation = (link) => {
    window.location.href = link; 
  };
  
  return (
    <Box
      sx={{
        backgroundColor: '#1D2939',
        width: '100%',
        padding: '64px 0 48px 0',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: { xs: '48px', md: '64px' },
      }}
    >
      <Box
        className="contentBox"
        sx={{
          marginX: '80px',
          px: { xs: '16px', md: '32px'},
          display: 'flex',
          flexDirection: 'column',
        }}
        gap="32px"
      >
        <Box sx={{ maxWidth: '320px' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              lineHeight: '24px',
            }}
          >
            {/* <CybersealAiLogo /> */}
            <CustomText
              text="Ezaix"
              textStyles={{
                fontWeight: '600',
                fontSize: '20px',
                alignSelf: 'flex-end',
                lineHeight: '24px',
                color: 'white',
              }}
            />
          </Box>
         {!matches &&  <CustomText
            text="Revolutionize Your Business with AI and Automation"
            textStyles={{
              color: '#EAECF0',
              fontSize: '16px',
              fontWeight: '400',
              lineHeight: '24px',
              textAlign: 'left',
              marginTop: '32px',
            }}
          />}
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '32px',
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row'},
            gap: { xs: '12px', md: '32px'},
            flex: { xs: '1', md: '0'},
          }}>
          {FOOTER_INFO.slice(0,3).map((info, index) => (
            <Button
              onClick={() => handleNavigation(info.link)}
              key={index}
              sx={{
                padding: '0',
                textTransform: 'none',
                '&.active': {
                  color: 'white',
                },
                width: 'max-content',
                minWidth: 'auto',
              }}
            >
              <CustomText
                text={info.title}
                textStyles={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  textDecoration: 'none',
                  color: '#EAECF0',
                  margin: '0',
                }}
              />
            </Button>
          ))}
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row'},
            gap: { xs: '12px', md: '32px'},
            flex: { xs: '1', md: '0'},
          }}>
          {FOOTER_INFO.slice(3).map((info, index) => (
            <Button
              onClick={() => handleNavigation(info.link)}
              key={index}
              sx={{
                padding: '0',
                textTransform: 'none',
                '&.active': {
                  color: 'white',
                },
                width: 'max-content',
                minWidth: 'auto',
              }}
            >
              <CustomText
                text={info.title}
                textStyles={{
                  fontSize: '16px',
                  lineHeight: '24px',
                  textDecoration: 'none',
                  color: '#EAECF0',
                  margin: '0',
                }}
              />
            </Button>
          ))}
          </Box>
        </Box>
      </Box>
      <Box
        className={!matches ? 'contentBox' : 'footerBox'}
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row'},
          paddingTop: '32px',
          borderTop: { xs: '1px solid var(--Gray-600, #475467)', md: '1px solid #D0D5DD'},
          gap: { xs: '16px', md: '32px' },
        }}
      >
        {matches && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: '16px',
          }}>
            {FOOTER_POLICIES.map((policy, index) => (
              <Button
                onClick={() => handleNavigation(policy.link)}
                key={index}
                sx={{
                  padding: '0',
                  textTransform: 'none',
                  '&.active': {
                    color: 'white',
                  },
                  width: 'max-content',
                  minWidth: 'auto',
                }}
              >
                <CustomText
                  text={policy.title}
                  textStyles={{
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: '400',
                    textDecoration: 'none',
                    color: '#D0D5DD',
                    margin: '0',
                  }}
                />
              </Button>
            ))}
          </Box>
        )}
        <CustomText
          text={`© ${currentYear} Ezaix. All rights reserved.`}
          textStyles={{
            color: '#D0D5DD',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            textAlign: 'left',
            flex: 1,
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
          gap="24px"
        >
          {FOOTER_SOCIAL_LINKS.map((link, index) => (
            <a href={link?.link} key={index} target="_blank" rel="noopener noreferrer">
            {link.icon}
          </a>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
