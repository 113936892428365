import { Box } from '@mui/material'
import React from 'react';
import CustomText from 'store/General/CustomText';

import IntroIcon from 'store/images/AboutUs/aboutUs-1.png';
import Matches from 'store/matches';

const FirstSection = () => {

  const matches = Matches();
  
  return (
    <Box
    sx={{
      mx: { xs: '0px', md: '41px'},
      minWidth: { xs: 'auto', md: '1198px'},
      alignSelf: 'center',
      display: 'flex',
      flexDirection: 'row',
      position: { xs: 'static', md: 'relative' },
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    }}>
      <Box 
        sx={{
          maxWidth: { xs: 'auto', md: '504px'},
          position: { xs: 'static', md: 'absolute' },
            top: '-32px',
            left: '45px',
        }}
      >
        {matches && (
          <CustomText
          text='About us'
          textStyles={{
            fontSize: '14px',
            lineHeight: '20px',
            color: '#7F56D9',
            marginBottom: '12px',
          }}
        />
        )}
        <CustomText 
          text='ADVANCE YOUR BUSINESS WITH AI, SECURITY & TECH...'
          textStyles={{
            textShadow: { xs: 'none', md: '0px 4px 4px #B9B9B9'},
            fontFamily: { xs: 'Inter', md: 'Roboto'},
            fontSize: { xs: '36px', md: '55px'},
            fontWeight: { xs: '700', md: '800'},
            lineHeight: { xs: '44px', md: '70px'},
            letterSpacing: { xs: '-0.72px', md: '3.3px'},
            background:  'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
            backgroundClip: 'text',
            textAlign: { xs: 'center', md: 'left'},
          }}
        />
      </Box>
      {!matches && (
        <img src={IntroIcon} alt='intro-icon' style={{
          display: 'flex',
          alignSelf: 'flex-end',
          objectFit: 'cover',
        }}
        width={866}
        height={592}
        />
      )}
    </Box>
  )
}

export default FirstSection