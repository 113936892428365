import { Box, useMediaQuery, useTheme } from '@mui/material';
import Header from 'store/Header';
import FirstSection from './FirstSection';
import ThirdSection from './ThirdSection';
import ForthSection from './FourthSection';
import FifthSection from './FifthSection';
import SixthSection from './SixthSection';
import SeventhSection from './SeventhSection';
import EigthSection from './EighthSection';
import CTASection from './CTASection';
import FaqSection from './FaqSection';

function Home() {
  const theme = useTheme();
  
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      <Box
        sx={{
          width: '100%',
          // height: '1116px',
          background:
            'linear-gradient(38.02deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Header backgroundIsPresent={true}/>
        <FirstSection />
      </Box>

      {/* {!matches && <CompaniesAssociated />} */}
      <ThirdSection />
      <ForthSection />
      <FifthSection />
     <SixthSection />
      <Box className={!matches ? "contentBox" : ""} sx={{display: 'flex', flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: { xs: '64px', md: '96px'},
        gap: { xs: '64px', md: '96px' },
      }}>
        <SeventhSection />
        <EigthSection />
        <CTASection/>
        <FaqSection />
      </Box>
    </>
  );
}

export default Home;
