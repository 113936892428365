import { Box, Typography } from '@mui/material';

const SectionFive = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: { xs: '100%', md: '1280px' },
          boxSizing: 'border-box',
          // height: '416px',
          marginX: 'auto',
          padding: { xs: '0px 16px', md: '96px 32px 0 32px' },
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '32px',
          flexShrink: 0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '48px',
            alignSelf: 'stretch',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', md: '768px' },
              flexDirection: 'column',
              alignItems: 'center',
              gap: { xs: '12px', md: '20px' },
            }}
          >
            <Typography
              sx={{
                color: 'var(--Gray-900, #101828)',
                fontFamily: 'Inter',
                fontSize: { xs: '30px', md: '36px' },
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: { xs: '38px', md: '44px' },
                letterSpacing: '-0.72px',
                textAlign: 'center',
              }}
            >
              Why Choose Ezaix
            </Typography>
            <Typography
              sx={{
                color: 'var(--Gray-600, #475467)',
                fontFamily: 'Inter',
                fontSize: { xs: '18px', md: '20px' },
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: { xs: '28px', md: '30px' },
                textAlign: 'center',
                display: 'flex',
                gap: '3px',
              }}
            >
              Welcome to{'  '}
              Ezaix : Pioneering Future-Ready Enterprise Solutions
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '24px',
              flexDirection: { xs: 'column', md: 'row' },
              alignSelf: 'stretch',
            }}
          >
            <Box
              sx={{
                background: 'var(--Gray-50, #F9FAFB)',
                display: 'flex',
                height: { xs: 'auto', md: '274px' },
                padding: { xs: '20px', md: '24px' },
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: { xs: '48px', md: '24px' },
                flex: '1 0 0',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: { xs: '28px', md: '48px' },
                  height: { xs: '28px', md: '48px' },
                  // padding: { xs: '12px', md: '10px' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                  borderRadius: '10px',
                  background: 'var(--Primary-Buttons, #4F9CF9)',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M15 2V3.4C15 3.96005 15 4.24008 14.891 4.45399C14.7951 4.64215 14.6422 4.79513 14.454 4.89101C14.2401 5 13.9601 5 13.4 5H10.6C10.0399 5 9.75992 5 9.54601 4.89101C9.35785 4.79513 9.20487 4.64215 9.10899 4.45399C9 4.24008 9 3.96005 9 3.4V2M7.2 22H16.8C17.9201 22 18.4802 22 18.908 21.782C19.2843 21.5903 19.5903 21.2843 19.782 20.908C20 20.4802 20 19.9201 20 18.8V5.2C20 4.07989 20 3.51984 19.782 3.09202C19.5903 2.71569 19.2843 2.40973 18.908 2.21799C18.4802 2 17.9201 2 16.8 2H7.2C6.0799 2 5.51984 2 5.09202 2.21799C4.71569 2.40973 4.40973 2.71569 4.21799 3.09202C4 3.51984 4 4.0799 4 5.2V18.8C4 19.9201 4 20.4802 4.21799 20.908C4.40973 21.2843 4.71569 21.5903 5.09202 21.782C5.51984 22 6.07989 22 7.2 22Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '30px',
                  }}
                >
                  Innovative Solutions
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  We offer the latest in analytics and provisioning
                  technologies, designed to keep you ahead of the curve
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                background: 'var(--Gray-50, #F9FAFB)',
                display: 'flex',
                height: { xs: 'auto', md: '274px' },
                padding: { xs: '20px', md: '24px' },
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: { xs: '48px', md: '24px' },
                flex: '1 0 0',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: { xs: '28px', md: '48px' },
                  height: { xs: '28px', md: '48px' },
                  // padding: { xs: '12px', md: '10px' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                  borderRadius: '10px',
                  background: 'var(--Primary-Buttons, #4F9CF9)',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M3.00195 8L15.002 8M15.002 8C15.002 9.65686 16.3451 11 18.002 11C19.6588 11 21.002 9.65685 21.002 8C21.002 6.34315 19.6588 5 18.002 5C16.3451 5 15.002 6.34315 15.002 8ZM9.00195 16L21.002 16M9.00195 16C9.00195 17.6569 7.65881 19 6.00195 19C4.3451 19 3.00195 17.6569 3.00195 16C3.00195 14.3431 4.3451 13 6.00195 13C7.65881 13 9.00195 14.3431 9.00195 16Z"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '30px',
                  }}
                >
                  Tailored to Your Needs
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  Our platforms are customizable to fit your specific
                  operational requirements.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                background: 'var(--Gray-50, #F9FAFB)',
                display: 'flex',
                height: { xs: 'auto', md: '274px' },
                padding: { xs: '20px', md: '24px' },
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: { xs: '48px', md: '24px' },
                flex: '1 0 0',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  width: { xs: '28px', md: '48px' },
                  height: { xs: '28px', md: '48px' },
                  // padding: { xs: '12px', md: '10px' },
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexShrink: 0,
                  borderRadius: '10px',
                  background: 'var(--Primary-Buttons, #4F9CF9)',
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                >
                  <rect
                    x="9.74805"
                    y="9.75"
                    width="7.5"
                    height="7.5"
                    fill="white"
                  />
                  <rect
                    x="0.748047"
                    y="9.75"
                    width="7.5"
                    height="7.5"
                    fill="white"
                  />
                  <rect
                    x="9.74805"
                    y="0.75"
                    width="7.5"
                    height="7.5"
                    fill="white"
                  />
                  <rect
                    x="0.748047"
                    y="0.75"
                    width="7.5"
                    height="7.5"
                    fill="white"
                  />
                </svg>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '30px',
                  }}
                >
                  Azure Excellence
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  With the backing of Azure's robust cloud infrastructure, our
                  solutions offer unmatched scalability, security, and
                  performance
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SectionFive;
