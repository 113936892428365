import {
  Box, Typography,
  Button,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { ReactComponent as ArrowRightIcon } from 'store/Logos/icons/arrow-right.svg';
import { ReactComponent as PlayIcon } from 'store/Logos/icons/play-icon.svg';

import { ReactComponent as HeroImage } from 'store/images/home-hero.svg';

function FirstSection() {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const handleNavigation = (link) => {
    window.location.href = link; 
  };

  return (
    <>
      <Box
        className="contentBox"
        sx={{
          flexGrow: 1, // This will make the component take up all available space
        }}
      >
        {/* Body */}
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: { xs: '128px', md: '192px' },
          }}
        >
          {!matches && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '16px',
                border: '1.5px solid rgba(255, 255, 249, 0.80)',
                p: '4px',
                color: '#FFFFFFCC',
              }}
            >
              <Box
                sx={{
                  borderRadius: '16px',
                  border: '1.5px solid rgba(255, 255, 249, 0.80)',
                  // mx: '3px'
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '20px',
                    px: '10px',
                  }}
                >
                  New feature
                </Typography>
              </Box>

              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '20px',
                  margin: '0 4px 0 12px',
                }}
              >
                Core expertise in AI and unified communications
              </Typography>
              <ArrowRightIcon />
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // maxWidth:'100%',
              // width: '1200px',
              my: { xs: '16px', md: '10px' },
            }}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: { xs: '36px', md: '60px' },
                lineHeight: { xs: '44px', md: '80px' },
                letterSpacing: { xs: '-0.72px', md: '-1.2px' },
                textAlign: 'Center',
                color: 'white',
                maxWidth: '100%',
                width: { xs: '100%', md: '1000px' },
              }}
            >
              Revolutionize Your Business with AI and Automation
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              maxWidth: '100%',
              width: '770px',
            }}
          >
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: { xs: '18px', md: '20px' },
                lineHeight: { xs: '28px', md: '30px' },
                textAlign: 'center',
                color: 'rgba(255, 255, 255, 0.8)',
              }}
            >
              Empowering transformation across Federal, Public Sector,
              Healthcare, Fintech, Manufacturing, and Retail with cutting-edge
              AI solutions
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignContent: 'center',
              justifyContent: 'center',
              gap: { xs: '12px', md: '24px' },
              mt: '32px',
              width: '100%',
            }}
          >
            <Button
              variant="contained"
              color="tertiary"
              onClick={() => handleNavigation('/contact')}
              sx={{
                height: '60px',
                color: 'white',
                gap: '8px',
                textTransform: 'none',
                padding: '16px 28px',
                borderRadius: '8px',
              }}
            >
              <PlayIcon />
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '16px', md: '18px' },
                  lineHeight: '28px',
                  color: '#344054',
                }}
              >
                Get Started For Free
              </Typography>
            </Button>

            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleNavigation('/contact')}
              sx={{
                height: '60px',
                color: 'white',
                textTransform: 'none',
                padding: '16px 28px',
                borderRadius: '8px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '16px', md: '18px' },
                  lineHeight: '28px',
                }}
              >
                Schedule a Call
              </Typography>
            </Button>
          </Box>
        </Box>

        <Box
          sx={{
            height: 'auto',
            margin: { xs: '64px 0 64px 0', md: '64px 0 0 0' },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <HeroImage height={{ xs: '235px', md: 'auto' }} />
        </Box>
      </Box>
    </>
  );
}

export default FirstSection;
