import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import CustomText from 'store/General/CustomText';

import { ReactComponent as MenuIcon } from 'store/Logos/icons/menu-icon.svg';
import { ReactComponent as MenuDarkIcon } from 'store/Logos/icons/menu-icon-dark.svg';
import CybersealAiLogo from 'store/Logos/cybersealAiLogo'

import DrawerLogic from './drawer';
import { useEffect, useState } from 'react';

function Header({ backgroundIsPresent }) {

  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(false);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const changeColor = () => {
      let threshold = !matches ? 80 : 90;
      if (window.scrollY >= threshold) {
        setColor(true);
      } else {
        setColor(false);
      }
    };

    window.addEventListener('scroll', changeColor);
    return () => window.removeEventListener('scroll', changeColor);
  }, []);
  
  const handleNavigation = (link) => {
    window.location.href = link; 
  };

  const textColor =
    'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)';

  const textStyle = {
    fontSize: '16px',
    lineHeight: '24px',
    textDecoration: 'none',
    background: backgroundIsPresent ? 'white' : textColor,
    color: backgroundIsPresent ? 'white' : 'transparent',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  };

  const buttonStyle = {
    padding: '0',
    textTransform: 'none',
    minWidth: 'auto',
  };

  return (
    <>
    <Box
      className="contentBox"
      sx={{
        py: { xs: '15px', md: '18px' },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        borderBottom: backgroundIsPresent ? '' : '1px solid var(--Gray-100, #F2F4F7)',
        position: 'fixed',
        top: '0',
        zIndex: '1000',
        background: backgroundIsPresent ? (color ? 'linear-gradient(to right, #04053E, #050425, #050318)': 'transparent') : 'white',
        width: '100%',
        transition: 'background 0.5s ease-in-out'
      }}
    >
      <Box
        sx={{
          width: '80%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: backgroundIsPresent ? 'white' : textColor,
            lineHeight: '24px',
            textDecoration: 'none',
            cursor: 'pointer',
          }}
          onClick={() => handleNavigation('/')}
        >
          
          {/* <CustomText
            text="Ezaix"
            textStyles={{
              fontSize: '25px',
              fontWeight: '600',
              alignSelf: 'flex-end',
              background: backgroundIsPresent
                ? 'white'
                : 'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              marginY: '5px',
              maxHeight: 'auto',
            }}
          /> */}
          <CybersealAiLogo  color={backgroundIsPresent ? '#F2F4F7' : textColor} />
        </Box>

        <Box
          sx={{
            display: { xs: 'none', md: 'flex' }, // 'none' on xs screens, 'flex' on md screens and above
            alignItems: 'center',
            gap: '32px',
            color: backgroundIsPresent ? 'white' : textColor,
            marginLeft: '200px',
          }}
        >
          <Button onClick={() => handleNavigation('/')} sx={buttonStyle}>
            <CustomText text="Home" textStyles={textStyle} />
          </Button>
          <Button onClick={() => handleNavigation('/aboutUs')} sx={buttonStyle}>
            <CustomText text="About Us" textStyles={textStyle} />
          </Button>
          <Button onClick={() => handleNavigation('/solution')} sx={buttonStyle}>
            <CustomText text="Solutions" textStyles={textStyle} />
          </Button>
          <Button onClick={() => handleNavigation('/partner')} sx={buttonStyle}>
            <CustomText text="Partner With Us" textStyles={textStyle} />
          </Button>
          <Button onClick={() => handleNavigation('/privacy-policy')} sx={buttonStyle}> 
            <CustomText text="Privacy Policy" textStyles={textStyle} />
          </Button>
          {/* <Button component={NavLink} to="/blogs" sx={buttonStyle}>
            <CustomText text="Blogs" textStyles={textStyle} />
          </Button> */}
          {/* <CustomText text="Careers" textStyles={textStyle} /> */}
        </Box>

        {/* Action Buttons */}
        <Box
          sx={{
            display: { xs: 'none', md: 'flex' },
            alignItems: 'center',
            gap: '12px',
            color: '#344054',
          }}
        >
          <Button
            variant="text"
            color="inherit"
            onClick={() => handleNavigation('/contact')}
            sx={{
              textTransform: 'none',
              padding: '10px 18px',
              borderRadius: '8px',
            }}
          >
            <CustomText
              text="Get Started"
              textStyles={{
                fontSize: '16px',
                lineHeight: '24px',
                color: backgroundIsPresent ? 'white' : textColor,
              }}
            />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleNavigation('/contact')}
            sx={{
              textTransform: 'none',
              padding: '10px 18px',
              borderRadius: '8px',
            }}
          >
            <CustomText
              text="Schedule a Call"
              textStyles={{
                fontSize: '16px',
                lineHeight: '24px',
                color: 'white',
              }}
            />
          </Button>
        </Box>
        <Button sx={{
          display: { xs: 'flex', md: 'none' },
          minWidth: 'fit-content',
          minHeight: 'fit-content',
          padding: '8px',
        }}
        onClick={() => setOpen(true)}
        >
          {!backgroundIsPresent ? <MenuDarkIcon /> : <MenuIcon />}
        </Button>
      </Box>
    </Box>

    <DrawerLogic open={open} onClose={() => setOpen(false)}/>
    </>
  );
}

export default Header;
