import { Box } from '@mui/material'
import React from 'react'
import CustomText from 'store/General/CustomText'

const MiniTitle = ({title, styles}) => {
  return (
    <Box sx={{
      padding: '4px 12px',
      borderRadius: '16px',
      background: 'rgba(3, 6, 76, 0.15)',
      mixBlendMode: 'multiply',
      maxWidth: 'fit-content',
      ...styles
    }}>
      <CustomText
        text={title}
        textStyles={{
          fontSize: '14px',
          lineHeight: '20px',
          background: 'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        }}
      />
    </Box>
  )
}

export default MiniTitle;