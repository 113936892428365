import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CustomText from 'store/General/CustomText';

const AccordionExpand = ({ title, desc, index }) => {
  const [expanded, setExpanded] = React.useState(false);

  const headerStyles = {
    color: '#101828',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '28px',
    textAlign: 'left',
  };

  const contentStyles = {
    color: '#475467',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
    textAlign: 'left',
  };

  // Toggle the expanded state
  const handleExpandChange = (event, isExpanded) => {
    setExpanded(isExpanded);
  };

  return (
    <Accordion
      onChange={handleExpandChange}
      expanded={expanded}
      sx={{
        boxShadow: 'none',
        '&.Mui-expanded::before': {
          opacity: 1,
        },
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded ? <RemoveCircleOutlineIcon style={{color: "rgba(152, 162, 179, 1)"}} /> : <ControlPointIcon style={{color: "rgba(152, 162, 179, 1)"}}/>
        }
        aria-controls="panel1-content"
        id="panel1-header"
        sx={{
          minHeight: '28px',
          '&.Mui-expanded': {
            minHeight: '28px',
          },
          '.MuiAccordionSummary-content': {
            margin: 0,
            ...(expanded && { marginBottom: '8px' }),
          },
          ...(index !== 0 ? { padding: '24px 0 0' } : { padding: '0px' }),
        }}
      >
        <CustomText text={title} textStyles={headerStyles} />
      </AccordionSummary>
      <AccordionDetails sx={{ padding: '0px' }}>
        <CustomText text={desc} textStyles={contentStyles} />
      </AccordionDetails>
    </Accordion>
  );
};

export default AccordionExpand;
