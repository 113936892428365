import { Box, Typography } from '@mui/material';
import messageImage from 'store/images/message-chat-circle.svg';
import Microsoft from 'store/images/microsoft.svg';
import Clipboard from 'store/images/clipboard02.svg';
import Settings from 'store/images/settings-04.svg';
import contentImage from 'store/images/glassTouch.png';

const SectionFour = () => {
  return (
    <>
      <Box
        sx={{
          background: 'var(--Base-White, #FFF)',
          display: 'flex',
          padding: '96px 0px',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '64px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            boxSizing: 'border-box',
            width: { xs: 'auto', md: '1280px' },
            padding: { xs: '0px 16px', md: '0px 32px' },
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: { xs: '48px', md: '64px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', md: '1216px' },
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: { xs: '16px', md: '20px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',
              }}
            >
              <Typography
                sx={{
                  background:
                    'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '24px',
                }}
              >
                Provisioning Solutions
              </Typography>
              <Typography
                sx={{
                  width: { xs: '100%', md: '1200px' },
                  color: 'var(--Gray-900, #101828)',
                  fontFamily: 'Inter',
                  fontSize: { xs: '30px', md: '36px' },
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: { xs: '38px', md: '44px' },
                  letterSpacing: '-0.72px',
                }}
              >
                Automate and Optimize Your Workflows{' '}
              </Typography>
            </Box>
            <Typography
              sx={{
                width: { xs: '100%', md: '1209px' },
                color: 'var(--Gray-600, #475467)',
                fontFamily: 'Inter',
                fontSize: { xs: '18px', md: '20px' },
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: { xs: '28px', md: '30px' },
              }}
            >
              Our provisioning solutions are designed to automate and manage any
              aspect of your business workflows. <br />
              <br /> From communications to other critical business processes,
              we enable enterprises to achieve greater efficiency and
              productivity through automation, with the reliability and security
              of Azure infrastructure.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            boxSizing: 'border-box',
            flexDirection: { xs: 'column', md: 'row' },
            width: { xs: '100%', md: '1280px' },
            padding: { xs: '0px 16px', md: '0px 32px' },
            alignItems: 'center',
            justifyContent: 'center',
            gap: { xs: '48px', md: '64px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: { xs: '40px', md: '48px' },
              flex: '1 0 0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  borderRadius: '28px',
                  border:
                    '8px solid var(--secondary-20, rgba(167, 206, 252, 0.20)',
                  background: 'var(--primary-40, rgba(167, 206, 252, 0.40)',
                  display: 'flex',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={messageImage} alt="messageImg" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: '0 0 0 0',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '30px',
                  }}
                >
                  Workflow Automation{' '}
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  Simplify and automate your business processes, from routine
                  tasks to complex operations
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  borderRadius: '28px',
                  border:
                    '8px solid var(--secondary-20, rgba(167, 206, 252, 0.20)',
                  background: 'var(--primary-40, rgba(167, 206, 252, 0.40)',
                  display: 'flex',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={Settings} alt="messageImg" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: '0 0 0 0',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '30px',
                  }}
                >
                  Custom Integration{' '}
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  Seamlessly integrate with your existing systems, ensuring a
                  smooth transition to automated workflows
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  borderRadius: '28px',
                  border:
                    '8px solid var(--secondary-20, rgba(167, 206, 252, 0.20)',
                  background: 'var(--primary-40, rgba(167, 206, 252, 0.40)',
                  display: 'flex',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={Clipboard} alt="messageImg" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: '0 0 0 0',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '30px',
                  }}
                >
                  Scalable Solutions
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  Whether you're a small business or a global enterprise, our
                  solutions adapt to your needs.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  borderRadius: '28px',
                  border:
                    '8px solid var(--secondary-20, rgba(167, 206, 252, 0.20)',
                  background: 'var(--primary-40, rgba(167, 206, 252, 0.40)',
                  display: 'flex',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={Microsoft} alt="messageImg" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: '0 0 0 0',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: '30px',
                  }}
                >
                  Azure-Powered
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  Leveraging Azure services, we guarantee a secure, reliable,
                  and high-performing environment for all your provisioning
                  needs.
                </Typography>
              </Box>
            </Box>
          </Box>
          <img
            src={contentImage}
            alt="contentImage"
            style={{
              borderRadius: '24px',
              background:
                'url(<path-to-image>) lightgray 50% / cover no-repeat',
              width: 'min(100%,561px)',
              height: { xs: '307px', md: '541px' },
              objectFit: 'cover',
              flexShrink: 0,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default SectionFour;
