import { Box, Button } from '@mui/material';
import { BLOG_POST_CARD_INFO } from 'constants/blog';
import { useNavigate } from 'react-router-dom';
import CustomText from 'store/General/CustomText';
import { ReactComponent as ArrowUpRightIcon } from 'store/images/arrow-up-right.svg';

import { ReactComponent as ChevronDownIcon } from 'store/Logos/icons/chevron-full-down.svg';
const SecondSection = ({ searchTerm }) => {

  const navigate = useNavigate();

  const filteredBlogPosts = BLOG_POST_CARD_INFO.filter((card) =>
    card.subHeading.toLowerCase().includes(searchTerm.toLowerCase()) ||
    card.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
    card.authorName.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box
      className="contentBox"
      sx={{
        padding: '103px 112px 58px 112px',
        display: 'grid',
        gridTemplateColumns: 'auto auto auto',
        columnGap: '32px',
        rowGap: '64px',
        alignSelf: 'center',
        width: '1280px',
        justifyContent: 'center',
      }}
    >
      {filteredBlogPosts.map((card) => (
        <Box
          key={card.index}
          sx={{
            padding: '24px 24px 32px 24px',
            background: 'white',
            boxShadow:
              '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)',
            width: '336px',
          }}
        >
          {card.img}
          <Box sx={{ marginTop: '32px' }}>
            <CustomText
              text={card.subHeading}
              textStyles={{
                fontSize: '14px',
                lineHeight: '20px',
                color: '#4F9CF9',
                textAlign: 'left',
                marginBottom: '12px',
              }}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginBottom: '8px',
              }}
            >
              <CustomText
                text={card.title}
                textStyles={{
                  fontSize: '24px',
                  lineHeight: '32px',
                  color: '#101828',
                  textAlign: 'left',
                }}
              />
              <Box sx={{ cursor: 'pointer', marginLeft: '16px'}}>
              <ArrowUpRightIcon onClick={() => navigate(`/blogs/${card.index}`, { state: { ...card, img: undefined, authorImg: undefined } })}  />
              </Box>
            </Box>
            <CustomText
              text={card.desc}
              textStyles={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: '400',
                color: '#475467',
                textAlign: 'left',
              }}
            />
            <Box
              sx={{ display: 'flex', flexDirection: 'row', marginTop: '24px' }}
              gap="12px"
            >
              {card.authorImg}
              <Box>
                <CustomText
                  text={card.authorName}
                  textStyles={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    textAlign: 'left',
                  }}
                />
                <CustomText
                  text={card.date}
                  textStyles={{
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#475467',
                    textAlign: 'left',
                    fontWeight: '400',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      ))}
      
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%', 
          gridColumn: '1 / -1', 
          marginTop: '64px',
        }}
      >
        <Button
          variant="contained"
          color="secondary"
          sx={{
            height: '48px',
            borderRadius: '8px',
            border: '1px solid #4F9CF9',
            padding: '12px 20px',
            textTransform: 'none',
            maxWidth: 'fit-content',
          }}
        >
          <ChevronDownIcon />
          <CustomText
            text="Load More"
            textStyles={{
              fontSize: '16px',
              lineHeight: '24px',
              color: '#FFF',
              padding: '0',
              marginLeft: '8px',
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default SecondSection;
