import { Box } from '@mui/material';
import Testimonial from 'store/Testimonial';

import testy1 from 'store/images/testimonials/abtus1.png';
import testy2 from 'store/images/testimonials/abtus2.png';
import testy3 from 'store/images/testimonials/abtus3.png';
import testy4 from 'store/images/testimonials/abtus4.png';

const ContentSection = () => {
  return (
    <Box
      className="contentBox"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '48px', md: '96px' },
        width: { xs: '100%', md: '1192px' },
        paddingBottom: '96px',
      }}
    >
      <Testimonial
        headerText="Empowering Businesses with Azure Intelligence"
        desc="At Ezaix, we understand the critical role that intelligent automation plays in scaling and streamlining operations. Our expertise in Microsoft Azure allows us to create custom AI solutions that not only automate routine tasks but also provide strategic insights, making your business more agile and responsive.
        From optimizing cloud resources with Azure to implementing sophisticated workflows with Azure DevOps, Ezaix is your partner in digital transformation."
        CoverImage={
          <img
            src={testy1}
            alt="testy1"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
            />
          }
        isAboutUs={true}
      />
      <Testimonial
        headerText="Innovative Solutions with Azure OpenAI and Microsoft Graph API"
        desc="Our collaboration with Microsoft extends to leveraging Azure OpenAI to revolutionize customer interactions and enhance decision-making processes. Ezaix utilizes the latest in AI research and applications to deliver solutions that understand and anticipate your needs. 
      Moreover, with the Microsoft Graph API, we integrate rich, contextual data into your applications, offering a seamless and connected experience across your digital ecosystem"
        CoverImage={
          <img
            src={testy2}
            alt="testy2"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
          />
        }
        rowReverse={true}
        isAboutUs={true}
      />
      <Testimonial
        headerText="Transforming Telecommunications with Conversational AI"
        desc="Ezaix is pioneering the future of telecommunications and contact centers through Conversational AI. By integrating advanced AI models, we offer solutions that improve customer service, reduce response times, and personalize user interactions. 
       Our approach ensures that businesses can meet their customers’ needs more efficiently, enhancing overall satisfaction and loyalty."
        CoverImage={
          <img
            src={testy3}
            alt="testy3"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
            />
          }
        isAboutUs={true}
      />
      <Testimonial
        headerText="Leading IoT Innovations for Metrics and Analysis"
        desc="Our commitment to innovation extends to IoT-based metrics, where Ezaix leverages Azure to collect, analyze, and interpret data from connected devices. This enables businesses to gain actionable insights, predict trends, and make data-driven decisions. 
       Ezaix’s IoT solutions are designed to optimize operations, reduce costs, and enhance product and service offerings."
        CoverImage={
          <img
            src={testy4}
            alt="testy4"
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
              borderRadius: '10px',
            }}
          />
        }
        rowReverse={true}
        isAboutUs={true}
      />
    </Box>
  );
};

export default ContentSection;
