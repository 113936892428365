import { Box, Button, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import CustomText from 'store/General/CustomText';

import CtaImage from 'store/images/ctaImage.png';

const CTASection = () => {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleNavigation = (link) => {
    window.location.href = link;
  };

  const contentStyles = {
    color: '#E9D7FE',
    fontSize: { xs: '16px', md: '20px'},
    fontWeight: '400',
    lineHeight: '30px',
    textAlign: { xs: 'center', md: 'left'},
  };

  return (
    <Box
      sx={{
        marginY: { xs: '0px', md: '69px' },
        px: { xs: '0', md: '32px' },
        width: { xs: '100%', md: '1280px' },
        
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          borderRadius: { xs: '0', md: '24px'},
          background:
            'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
        }}
      >
        <Box sx={{ padding: { xs: '64px 16px', md: '64px' } }}>
          <CustomText
            text="Partner with Ezaix"
            textStyles={{
              color: 'white',
              textAlign: { xs: 'center', md: 'left'},
              marginBottom: { xs: '16px', md: '20px'},
              fontSize: { xs: '30px', md: '36px' },
            }}
          />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: { xs: '32px', md: '40px'},
            }}
            gap="30px"
          >
            <CustomText
              text="Join forces with Ezaix and embark on a journey of continuous innovation and growth."
              textStyles={contentStyles}
            />
            <CustomText
              text="Explore the future of enterprise technology with Ezaix. Let's innovate together and set new standards for your industry."
              textStyles={contentStyles}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row'},
              gap: '12px',
            }}
          >
            <Button
              variant="contained"
              color="tertiary"
              onClick={() => handleNavigation('/partner')}
              sx={{
                alignSelf: 'center',
                height: '48px',
                borderRadius: '8px',
                border: '1px solid #D0D5DD',
                padding: '12px 20px',
                width: { xs: '100%', md: 'auto' },
                textTransform: 'none',
              }}
            >
              <CustomText
                text="Learn More"
                textStyles={{
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#344054',
                  padding: '0',
                }}
              />
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleNavigation('/contact')}
              sx={{
                alignSelf: 'center',
                height: '48px',
                borderRadius: '8px',
                border: '1px solid #4F9CF9',
                padding: '12px 20px',
                width: { xs: '100%', md: 'auto' },
                textTransform: 'none',
              }}
            >
              <CustomText
                text="Get in Touch"
                textStyles={{
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: 'white',
                  padding: '0',
                }}
              />
            </Button>
          </Box>
        </Box>
        {!matches && (
          <img
            src={CtaImage}
            alt="cta"
            style={{ borderRadius: '0 24px 24px 0' }}
            width={480}
          />
        )}
      </Box>
    </Box>
  );
};

export default CTASection;
