import Snackbar from '@mui/material/Snackbar';
import { Alert, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const AlertBox = ({ open, handleClose, status }) => {
  return (
    <Box>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{vertical: 'top', horizontal: 'center'}} >
        <Alert
          onClose={handleClose}
          severity={status}
          icon={status === 'success' ? <CheckIcon fontSize="inherit" /> : <ErrorOutlineIcon />}
          variant="filled"
          sx={{ width: '100%' }}
          action={<></>}
        >
          {status === 'success' ? 'Thanks for reaching out info@ezaix.com' : 'Something went wrong. Please try again!'}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AlertBox;
