import { Box } from '@mui/material';
import React from 'react';
import CustomText from '../CustomText';

const Badge = () => {
  const textStyle = {
    fontSize: '12px',
    lineHeight: '18px',
    textAlign: 'left',
    fontWeight: '500',
    background:
      'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    alignSelf: 'center',
  };

  return (
    <Box
      sx={{
        padding: '4px 10px 4px 4px',
        display: 'flex',
        borderRadius: '16px',
        background: 'var(--secondary-20, rgba(167, 206, 252, 0.20))',
        mixBlendMode: 'multiply',
        maxWidth: 'fit-content',

      }}
      gap="8px"
    >
      <Box
        sx={{
          padding: '2px 8px',
          borderRadius: '16px',
          background: '#FFF',
          maxWidth: 'fit-content',
        }}
      >
        <CustomText text="Design" textStyles={textStyle} />
      </Box>
      <CustomText text="8 min read" textStyles={textStyle} />
    </Box>
  );
};

export default Badge;
