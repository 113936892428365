import BlogPostImage1 from 'store/images/Blog/HeroImgs/blog1.png';
import BlogPostImage2 from 'store/images/Blog/HeroImgs/blog2.jpg';
import BlogPostImage3 from 'store/images/Blog/HeroImgs/blog3.png';
import BlogPostImage4 from 'store/images/Blog/HeroImgs/blog4.png';
import BlogPostImage5 from 'store/images/Blog/HeroImgs/blog5.png';
import BlogPostImage6 from 'store/images/Blog/HeroImgs/blog6.png';
import BlogPostImage7 from 'store/images/Blog/HeroImgs/blog7.jpg';
import BlogPostImage8 from 'store/images/Blog/HeroImgs/blog8.png';
import BlogPostImage9 from 'store/images/Blog/HeroImgs/blog9.png';

import AuthorImage1 from 'store/images/Blog/AuthorImgs/avatar1.png';
import AuthorImage2 from 'store/images/Blog/AuthorImgs/avatar2.png';
import AuthorImage3 from 'store/images/Blog/AuthorImgs/avatar3.png';
import AuthorImage4 from 'store/images/Blog/AuthorImgs/avatar4.png';
import AuthorImage5 from 'store/images/Blog/AuthorImgs/avatar5.png';
import AuthorImage6 from 'store/images/Blog/AuthorImgs/avatar6.png';
import AuthorImage7 from 'store/images/Blog/AuthorImgs/avatar7.png';
import AuthorImage8 from 'store/images/Blog/AuthorImgs/avatar8.png';
import AuthorImage9 from 'store/images/Blog/AuthorImgs/avatar9.png';



export const BLOG_POST_CARD_INFO = [
  {
    index: 1,
    img: <img src={BlogPostImage1} alt="blog1" />,
    subHeading: 'Automation',
    title: 'UX review presentations',
    desc: 'How do you create compelling presentations that wow your colleagues and impress your managers?',
    authorImg: <img src={AuthorImage1} alt="author1" />, 
    authorName: 'Olivia Rhye',
    date: '20 Jan 2022',
  },
  {
    index: 2,
    img: <img src={BlogPostImage2} alt="blog2" />,
    subHeading: 'Cyber Security',
    title: 'Migrating to Linear 101',
    desc: 'Linear helps streamline software projects, sprints, tasks, and bug tracking. Here’s how to get started.',
    authorImg: <img src={AuthorImage2} alt="author2" />, 
    authorName: 'Phoenix Baker',
    date: '19 Jan 2022',
  },
  {
    index: 3,
    img: <img src={BlogPostImage3} alt="blog3" />,
    subHeading: 'Software Engineering',
    title: 'Building your API Stack',
    desc: 'The rise of RESTful APIs has been met by a rise in tools for creating, testing, and managing them.',
    authorImg: <img src={AuthorImage3} alt="author3" />, 
    authorName: 'Lana Steiner',
    date: '18 Jan 2022',
  },
  {
    index: 4,
    img: <img src={BlogPostImage4} alt="blog4" />,
    subHeading: 'Automation',
    title: 'Bill Walsh leadership lessons',
    desc: 'Like to know the secrets of transforming a 2-14 team into a 3x Super Bowl winning Dynasty?',
    authorImg: <img src={AuthorImage4} alt="author4" />, 
    authorName: 'Alec Whitten',
    date: '17 Jan 2022',
  },
  {
    index: 5,
    img: <img src={BlogPostImage5} alt="blog5" />,
    subHeading: 'Product',
    title: 'PM mental models',
    desc: 'Mental models are simple expressions of complex processes or relationships.',
    authorImg: <img src={AuthorImage5} alt="author5" />, 
    authorName: 'Demi WIlkinson',
    date: '16 Jan 2022',
  },
  {
    index: 6,
    img: <img src={BlogPostImage6} alt="blog6" />,
    subHeading: 'Automation',
    title: 'What is Wireframing?',
    desc: 'Introduction to Wireframing and its Principles. Learn from the best in the industry.',
    authorImg: <img src={AuthorImage6} alt="author6" />, 
    authorName: 'Candice Wu',
    date: '15 Jan 2022',
  },
  {
    index: 7,
    img: <img src={BlogPostImage7} alt="blog7" />,
    subHeading: 'Cyber Security',
    title: 'How collaboration makes us better designers',
    desc: 'Collaboration can make our teams stronger, and our individual designs better.',
    authorImg: <img src={AuthorImage7} alt="author7" />, 
    authorName: 'Natali Craig',
    date: '14 Jan 2022',
  },
  {
    index: 8,
    img: <img src={BlogPostImage8} alt="blog8" />,
    subHeading: 'Telephony',
    title: 'Our top 10 Javascript frameworks to use',
    desc: 'JavaScript frameworks make development easy with extensive features and functionalities.',
    authorImg: <img src={AuthorImage8} alt="author8" />,
    authorName: 'Drew Cano',
    date: '13 Jan 2022',
  },
  {
    index: 9,
    img: <img src={BlogPostImage9} alt="blog9" />,
    subHeading: 'Communication services',
    title: 'Podcast: Creating a better CX Community',
    desc: 'Starting a community doesn’t need to be complicated, but how do you get started?',
    authorImg: <img src={AuthorImage9} alt="author9" />,
    authorName: 'Orlando Diggs',
    date: '12 Jan 2022',
  }
];