import { Box } from '@mui/material';
import CustomText from 'store/General/CustomText';

import { ReactComponent as InfoIcon } from 'store/images/AboutUs/info-icon.svg';

const Testimonial = ({
  CoverImage,
  headerText,
  desc,
  rowReverse,
  isAboutUs,
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: rowReverse ? 'row-reverse' : 'row' },
        paddingX: { xs: '16px', md: '0px' },
        gap: { xs: '40px', md: isAboutUs ? '64px' : '124px' },
        justifyContent: { xs: 'center', md: isAboutUs ? 'normal' : 'space-between' },
      }}
    >
      <Box sx={{ 
        width: { xs: 'auto', md: '534px' }, 
        alignSelf: 'center',
        padding: { xs: '0px', md: isAboutUs ? '64px 32px 64px 32px' : '0' },
      }}
        >
        {isAboutUs && <InfoIcon style={{
          marginBottom: '24px',
        }}/>}
        <CustomText
          text={headerText}
          textStyles={{
            color: '#1A202C',
            fontSize: { xs: '24px', md: isAboutUs ? '30px' : '28px' },
            lineHeight: { xs: '32px', md: '38.086px' },
            marginBottom: isAboutUs ? '16px' : '30px',
            textAlign: 'left',
          }}
        />
        <CustomText
          text={desc}
          textStyles={{
            color: '#2D3748',
            fontSize: { xs: '16px', md: '18px' },
            fontWeight: '400',
            lineHeight: { xs: '24px', md: isAboutUs ? '28px' : '30px' },
            width: { xs: 'auto', md: '534px' },
            height: isAboutUs ? 'auto' : '113px',
            textAlign: 'left',
            marginBottom: '10px',
          }}
        />
      </Box>
      <Box
        sx={{
          alignSelf: 'center',
          width: { xs: 'auto', md: '100%'},
        }}
      >
        {CoverImage}
      </Box>
    </Box>
  );
};

export default Testimonial;
