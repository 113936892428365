import { useMediaQuery, useTheme } from '@mui/material';

const Matches = () => {
  const theme = useTheme();
  
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return matches
}

export default Matches