import React from 'react';
import { Box, Typography } from '@mui/material';
import Content from 'store/images/Content.png';

function Partner() {
  return (
    <>
      {/* every one has to have width: 80% and margin auto or add class name contentBox */}

      <Box
        sx={{
          display: 'flex',
          padding: { xs: '48px 0px', md: '96px 0px'},
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: '48px', md: '64px' },
          alignSelf: 'stretch',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            // flexDirection: 'column',
            // justifyContent: 'center',
            // alignItems: 'center',
            alignSelf: { xs: 'flex-start', md: 'center' },

            gap: '48px',
            // alignSelf: 'stretch',
            padding: '16px',
          }}
        >
          <Typography
            sx={{
              color: 'var(--Gray-900, #101828)',
              textAlign: { xs: 'left', md: 'center' },
              /* Display md/Semibold */
              fontFamily: 'Inter',
              fontSize: { xs: '30px', md: '36px' },
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: { xs: '38px', md: '44px' } /* 122.222% */,
              letterSpacing: '-0.72px',
              textAlign: {xs: 'center', md: 'left'}
            }}
          >
            Tap into powerful partner benefits
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column-reverse', md: 'row' },
            width: { xs: '100%', md: '1280px' },
            padding: { xs: '0 16px', md: '0px 32px' },
            boxSizing: 'border-box',
            alignItems: 'center',
            gap: '50px',
          }}
        >
          <Box
            sx={{
              // height: { xs: '358px', md: '696px' },
              // width: 'min(100%,581px)',
              padding: { xs: '0 16px', md: '0' },
              objectFit: 'cover',
              borderRadius: '24px',
            }}
          >
            <img
              src={Content}
              alt="Descriptive Text"
              style={{
                // height: { xs: '358px', md: '696px' },
                objectFit: 'cover',
                width: 'min(100%,581px)',
                // width: { md: '576px', xs: '324px' },
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '35px',
              flex: '1 0 0',
              padding: '0 24px',
            }}
          >
            <Typography
              sx={{
                color: 'var(--Gray-900, #101828)',
                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px' /* 122.222% */,
              }}
            >
              Access to Advanced Technologies
            </Typography>
            <Typography
              sx={{
                color: 'var(--Gray-600, #475467)',
                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px' /* 122.222% */,
              }}
            >
              Leverage our expertise in AI and ML to bring sophisticated,
              future-ready solutions to your business, setting you apart from
              the competition.
            </Typography>

            <Typography
              sx={{
                color: 'var(--Gray-900, #101828)',
                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px' /* 122.222% */,
              }}
            >
              Dedicated Support and Expertise
            </Typography>

            <Typography
              sx={{
                color: 'var(--Gray-600, #475467)',
                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px' /* 122.222% */,
              }}
            >
              Benefit from our team's deep industry knowledge and unwavering
              support, ensuring seamless integration and optimization of our
              solutions within your operations.
            </Typography>
            <Typography
              sx={{
                color: 'var(--Gray-900, #101828)',
                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px' /* 122.222% */,
              }}
            >
              Scalable and Flexible Solutions
            </Typography>

            <Typography
              sx={{
                color: 'var(--Gray-600, #475467)',
                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px' /* 122.222% */,
              }}
            >
              Our technologies are designed to grow with you, providing scalable
              solutions that adapt to your evolving business needs and market
              demands.
            </Typography>
            <Typography
              sx={{
                color: 'var(--Gray-900, #101828)',
                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px' /* 122.222% */,
              }}
            >
              Operational Excellence
            </Typography>

            <Typography
              sx={{
                color: 'var(--Gray-600, #475467)',
                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px' /* 122.222% */,
              }}
            >
              Achieve unparalleled efficiency and productivity, reduce costs,
              and enhance decision-making through our smart technologies and
              data-driven insights.
            </Typography>
            <Typography
              sx={{
                color: 'var(--Gray-900, #101828)',
                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '20px' /* 122.222% */,
              }}
            >
              Strategic Advantage
            </Typography>

            <Typography
              sx={{
                color: 'var(--Gray-600, #475467)',
                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '24px' /* 122.222% */,
              }}
            >
              With our analytics and predictive capabilities, gain valuable
              insights that empower you to make informed strategic decisions,
              identify new opportunities, and stay ahead in your industry.
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Partner;
