import { Box } from '@mui/material';
import React from 'react';

import { ABOUTUS_SECOND } from 'constants/aboutUs';

const SecondSection = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', md: 'row' },
        justifyContent: 'flex-end',
        alignSelf: 'center',
        alignItems: 'flex-start',
        color: '#475467',
        fontSize: '18px',
        fontWeight: '400',
        lineHeight: '28px',
        fontFamily: 'Inter',
        textAlign: { xs: 'center', md: 'left'},
        pb: '96px',
      }}
      gap="64px"
    >
      <Box
        sx={{
          ml: {
            xs: '0px',
            md: '311px',
          },
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          width: {
            xs: 'auto',
            md: '388px',
          },
        }}
      >
        {ABOUTUS_SECOND.firstBox.map((item, index) => (
          <Box key={index}>
            <span style={{ fontWeight: 700, fontSize: '60px' }}>
              {item.descStart}
            </span>
            {item.desc}
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
          width: {
            xs: 'auto',
            md: '388px',
          },
        }}
      >
        {ABOUTUS_SECOND.secondBox.map((item, index) => (
          <Box key={index}>{item.desc}</Box>
        ))}
      </Box>
    </Box>
  );
};

export default SecondSection;
