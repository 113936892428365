import { Box } from '@mui/material';
import React from 'react';
import Badge from 'store/General/Badge';
import CustomText from 'store/General/CustomText';

const FirstSection = ({ data }) => {
  // const { img: Image, authorImg, title, desc, authorName, date } = data;

  return (
    <Box sx={{ padding: '64px 0 96px 0' }}>
      <Box sx={{ px: '32px', display: 'flex', flexDirection: 'row' }}>
        <Box sx={{ width: '800px' }}>
          <Box>
            <Badge />
            <CustomText
              text={data.title}
              textStyles={{
                fontSize: '60px',
                lineHeight: '72px',
                letterSpacing: '-1.2px',
                background:
                  'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textAlign: 'left',

                margin: '16px 0 24px 0',
              }}
            />
            <CustomText
              text={data.desc}
              textStyles={{
                fontSize: '20px',
                lineHeight: '30px',
                fontWeight: '400',
                color: '#475467',
                textAlign: 'left',
              }}
            />
          </Box>
          <Box
            sx={{
              marginTop: '48px',
            }}
          >
            {data?.img}
            <CustomText
              text={data.authorName}
              textStyles={{
                fontSize: '18px',
                lineHeight: '28px',
                textAlign: 'left',
              }}
            />
            <CustomText
              text={data.date}
              textStyles={{
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '24px',
                textAlign: 'left',
                color: '#475467',
              }}
            />
          </Box>
        </Box>
      {data.authorImg}

      </Box>
    </Box>
  );
};

export default FirstSection;
