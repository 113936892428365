export const FAQS = [
  {
    title: 'Is there a free trial available?',
    desc: 'Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.',
  },
  {
    title: 'Can I change my plan later?',
    desc: 'Yes, you can change your plan at any time. You can also add or remove users at any time.',
  },
  {
    title: 'What is your cancellation policy?',
    desc: 'You can cancel your subscription at any time. Your subscription will remain active until the end of the billing period.',
  },
  {
    title: 'Can other info be added to an invoice?',
    desc: 'Yes, you can add a billing address, VAT number, or any other information to your invoice.',
  },
  {
    title: 'How does billing work?',
    desc: 'You will be billed monthly or annually, depending on your plan. You can pay by credit card or bank transfer.',
  },
  {
    title: 'How do I change my account email?',
    desc: 'You can change your account email by contacting our support team.',
  },
];