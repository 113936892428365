import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import Header from 'store/Header';
import FirstSection from './FirstSection';

const BlogDetail = () => {
  const { state } = useLocation();

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <Header />
      <Box className="contentBox">
        <FirstSection data={state} />
      </Box>
    </Box>
  );
};

export default BlogDetail;
