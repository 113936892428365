export const PARTNER_SECOND =[
  {
    id: '1',
    title: 'Innovative Leadership',
    desc: 'Stay ahead of the curve with access to pioneering AI, ML, and IoT technologies that redefine what’s possible in business operations and customer engagement.',
  },
  {
    id: '2',
    title: 'Customized Strategies',
    desc: 'Every partnership is unique. We dedicate ourselves to understanding your specific challenges and goals, ensuring our solutions perfectly align with your business objectives.',
  },
  {
    id: '3',
    title: 'Growth Together',
    desc: 'Your success is our success. We are committed to a partnership model that prioritizes shared achievements and mutual benefits.',
  },
]