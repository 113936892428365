import React from 'react';
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import companiesLogos from 'store/images/companiesLogos.png';
import chairOffice from 'store/images/chairOffice.png';
import { height } from '@mui/system';
import Image1 from 'store/images/image1.png';

function ThirdSection() {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleNavigation = (link) => {
    window.location.href = link;
  };

  return (
    <>
      {/* every one has to have width: 80% and margin auto or add class name contentBox */}

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          className="contentBox"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignItems: 'flex-start',
            justifyContent: 'center',
            py: { xs: '64px', md: '96px' },
            height: { xs: 'auto', md: '550px' },
            maxWidth: '1280px',
          }}
          >
          <Box sx={{
            px: { xs: '16px', md: '25px' },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: '0', md: '64px' },
            alignItems: 'center',
          }}>
          <Box
            sx={{
              width: { xs: '100%', md: '576px' },
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
              backgroundColor: 'white',
            }}
          >
            <Typography
              sx={{
                fontWeight: '600',
                fontSize: '16px',
                lineHeight: '24px',
                textAlign: 'left',
                color: '#4F9CF9',
                marginBottom: '12px',
              }}
            >
              About Us
            </Typography>

            <Typography
              sx={{
                fontWeight: '600',
                fontSize: { xs: '30px', md: '36px' },
                lineHeight: { xs: '38px', md: '44px' },
                textAlign: 'left',
                color: '#101828',
                alignSelf: { xs: 'stretch' },
                marginBottom: { xs: '32px', md: '40px' },
              }}
            >
              Our Path to technological Empowerment
            </Typography>

            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '18px',
                lineHeight: '28px',
                textAlign: 'left',
                color: '#475467',
              }}
            >
              Ezaix is rooted in a vision of ambition, deep technological
              expertise, and unwavering commitment to excellence. Our journey
              began with a group of forward-thinking technologists and experts
              in communication who were driven by the desire to transform the
              business communication landscape.
            </Typography>

            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '18px',
                lineHeight: '28px',
                textAlign: 'left',
                color: '#475467',
                paddingTop: '25px',
              }}
            >
              From our early days as a dynamic startup, we've grown into a
              leader in the tech industry, guiding enterprises of all sizes
              towards a future where technology is a key driver of growth and
              innovation.
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: { xs: '32px' },
                gap: { xs: '12px', md: '15px' },
                width: { xs: '100%', md: 'auto' },
              }}
            >
              <Button
                variant="contained"
                onClick={() => handleNavigation('/contact')}
                color="tertiary"
                sx={{
                  height: '48px',
                  color: 'white',
                  textTransform: 'none',
                  padding: 0,
                  width: { xs: '100%', md: 'auto' },
                  borderRadius: '8px',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#344054',
                    padding: '12px 20px',
                  }}
                >
                  Get in Touch
                </Typography>
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => handleNavigation('/solution')}
                sx={{
                  height: '48px',
                  color: 'white',
                  textTransform: 'none',
                  padding: 0,
                  width: { xs: '100%', md: 'auto' },
                  borderRadius: '8px',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '24px',
                    padding: '12px 20px',
                  }}
                >
                  Our Solutions
                </Typography>
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              width: { xs: 'auto', md: '35%' },
              // px: { xs: '16px', md: '0' },
              marginTop: { xs: '48px', md: '0' },
              alignSelf: { xs: 'center', md: 'flex-start' },
            }}
          >
            <img
              src={chairOffice}
              alt="Descriptive Text"
              style={{
                borderRadius: '10px',
              }}
              height={matches ? '240px' : '592px'}
              width={matches ? '100%' : '576px'}
            />
          </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ThirdSection;
