import { Box, Typography } from '@mui/material';
import React from 'react';
import globeImage from 'store/images/solution_comms.png';
import messageImage from 'store/images/message-chat-circle.svg';
import lineChartUp from 'store/images/line-chart-up-01.svg';
import chartBreakout from 'store/images/chart-breakout-square.svg';
import monitor from 'store/images/monitor-01.svg';

const SectionTwo = () => {
  return (
    <>
      <Box
        sx={{
          background: 'var(--Base-White, #FFF)',
          display: 'flex',
          padding: { xs: '64px 0px', md: '96px 0px'},
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '64px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            boxSizing: 'border-box',
            width: { xs: 'auto', md: '1280px' },
            padding: { xs: '0px 16px', md: '0px 32px' },
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: { xs: '48px', md: '64px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', md: '1216px' },
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',
              }}
            >
              <Typography
                sx={{
                  background:
                    'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '24px',
                }}
              >
                Analytics Platform
              </Typography>
              <Typography
                sx={{
                  width: { xs: '100%', md: '1200px' },
                  color: 'var(--Gray-900, #101828)',
                  fontFamily: 'Inter',
                  fontSize: { xs: '30px', md: '36px' },
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: { xs: '38px', md: '44px' },
                  letterSpacing: '-0.72px',
                }}
              >
                Uncover Insights Across Every Communication
              </Typography>
            </Box>
            <Typography
              sx={{
                width: { xs: '100%', md: '1209px' },
                color: 'var(--Gray-600, #475467)',
                fontFamily: 'Inter',
                fontSize: { xs: '18px', md: '20px' },
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: { xs: '28px', md: '30px' },
              }}
            >
              Our state-of-the-art analytics platform transcends traditional
              data analysis by offering deep insights into a wide array of
              communication scenarios.
              <br />
              <br />
              Whether it's telephony, contact centers, converged communications,
              or IoT, our platform leverages Azure OpenAI and Azure Cosmos DB to
              transform raw data into actionable intelligence.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            boxSizing: 'border-box',
            flexDirection: { xs: 'column', md: 'row' },
            width: { xs: '100%', md: '1280px' },
            padding: { xs: '0px 16px', md: '0px 32px' },
            alignItems: 'center',
            justifyContent: 'center',
            gap: { xs: '48px', md: '64px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: { xs: '40px', md: '48px' },
              flex: '1 0 0',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                // alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  borderRadius: '28px',
                  border:
                    '8px solid var(--secondary-20, rgba(167, 206, 252, 0.20)',
                  background: 'var(--primary-40, rgba(167, 206, 252, 0.40)',
                  display: 'flex',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={messageImage} alt="messageImg" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: '0 0 0 0',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: { xs: '18px', md: '20px' },
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: { xs: '28px', md: '30px' },
                  }}
                >
                  Comprehensive Communication Analysis
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  From voice to digital channels, gain unparalleled insights
                  into your communication data
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  borderRadius: '28px',
                  border:
                    '8px solid var(--secondary-20, rgba(167, 206, 252, 0.20)',
                  background: 'var(--primary-40, rgba(167, 206, 252, 0.40)',
                  display: 'flex',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={lineChartUp} alt="messageImg" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: '0 0 0 0',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: { xs: '18px', md: '20px' },
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: { xs: '28px', md: '30px' },
                  }}
                >
                  Predictive Analytics
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  Utilize AI-driven predictions to anticipate customer needs and
                  trends, enabling proactive decision-making
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  borderRadius: '28px',
                  border:
                    '8px solid var(--secondary-20, rgba(167, 206, 252, 0.20)',
                  background: 'var(--primary-40, rgba(167, 206, 252, 0.40)',
                  display: 'flex',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={chartBreakout} alt="messageImg" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: '0 0 0 0',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: { xs: '18px', md: '20px' },
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: { xs: '28px', md: '30px' },
                  }}
                >
                  Real-time Reporting
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  Access real-time data visualizations and dashboards for
                  up-to-the-minute insights.
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}
            >
              <Box
                sx={{
                  borderRadius: '28px',
                  border:
                    '8px solid var(--secondary-20, rgba(167, 206, 252, 0.20)',
                  background: 'var(--primary-40, rgba(167, 206, 252, 0.40)',
                  display: 'flex',
                  width: '48px',
                  height: '48px',
                  padding: '12px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <img src={monitor} alt="messageImg" />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  padding: '0 0 0 0',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '8px',
                  alignSelf: 'stretch',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Gray-900, #101828)',
                    fontFamily: 'Inter',
                    fontSize: { xs: '18px', md: '20px' },
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: { xs: '28px', md: '30px' },
                  }}
                >
                  Scalable Infrastructure
                </Typography>
                <Typography
                  sx={{
                    color: 'var(--Gray-600, #475467)',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '24px',
                  }}
                >
                  Built on Azure, our platform scales effortlessly with your
                  data needs, ensuring robust performance and reliability.
                </Typography>
              </Box>
            </Box>
          </Box>
          <img
            src={globeImage}
            alt="globalImage"
            style={{
              borderRadius: '24px',
              background:
                'url(<path-to-image>) lightgray 50% / cover no-repeat',
              width: "min(100%,561px)",
              height: {xs: "307px", md:'541px'},
              objectFit: 'cover',
              flexShrink: 0,
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default SectionTwo;
