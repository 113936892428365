import { Box, useMediaQuery } from '@mui/material';
import React from 'react';

import MetricsImage from 'store/images/AboutUs/metrics.png';

import MetricsPotrait from 'store/images/AboutUs/metrics-potrait.png';
import { ABOUTUS_THIRD } from 'constants/aboutUs';
import CustomText from 'store/General/CustomText';

import Matches from 'store/matches';

const ThirdSection = () => {
  const matches = Matches();
  const matchesLessThan450 = useMediaQuery('(max-width:450px)');
  return (
    <Box
      sx={{
        width: { xs: '100%', md: 'auto' },
        mx: {
          xs: '0',
          md: '32px',
        },
        alignSelf: 'center',
        position: 'relative',
        overflow: 'hidden',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: 'auto',
          height: { xs: '85%', md: 'auto' },
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          position: 'absolute',
          margin: { xs: '32px 24px', md: '64px' },
          gap: { xs: '16px', md: '64px' },
          justifyContent: 'space-between',
        }}
      >
        {ABOUTUS_THIRD.map((item, index) => (
          <Box
            key={index}
            sx={{
              padding: matchesLessThan450 ? '16px' : '32px 24px',
              border: '1px solid rgba(255, 255, 255, 0.60)',
              background: 'rgba(255, 255, 255, 0.30)',
              backdropFilter: 'blur(12px)',
              height: 'auto',
            }}
          >
            <CustomText
              text={item.title}
              textStyles={{
                fontSize: { xs: '48px', md: '60px'},
                lineHeight: { xs: '60px', md: '72px'},
                letterSpacing: { xs: '-0.96px', md: '-1.2px'},
                color: 'white',
              }}
            />
            <CustomText
              text={item.desc1}
              textStyles={{
                margin: '12px 0 8px 0',
                fontSize: '18px',
                lineHeight: '28px',
                color: 'white',
              }}
            />
            <CustomText
              text={item.desc2}
              textStyles={{
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: '400',
                color: 'white',
              }}
            />
          </Box>
        ))}
      </Box>
      <img src={!matches ? MetricsImage : MetricsPotrait} alt="metric-pic" style={{
        width: '100%', 
        maxHeight: '115vh',  
      }} />
    </Box>
  );
};

export default ThirdSection;
