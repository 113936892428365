export const CONTACT_LEFT_SECTION = [
  {
    title: 'Support',
    desc: 'Our friendly team is here to help.',
    contact: 'support@ezaix.com'
  },
  {
    title: 'Phone',
    desc: 'Mon-Fri from 6 AM to 5 PM PST.',
    contact: '+18055626716',
  }
]