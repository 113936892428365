import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';

function SixthSection() {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
      {/* every one has to have width: 80% and margin auto or add class name contentBox */}

      <Box
        sx={{
          width: '100%',
          py: { xs: '64px', md: '115px' },
        }}
      >
        <Box
          className="contentBox"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '50px',
          }}
        >
          {!matches && (
            <Box
              sx={{
                width: '1216px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '5px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '36px',
                  lineHeight: '44px',
                  textAlign: 'Center',
                }}
              >
                Unleash the full power of AI and Analytics
              </Typography>

              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '20px',
                  lineHeight: '30px',
                  textAlign: 'Center',
                }}
              >
                Everything you need to monitor, engage and increase productivity
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              width: { xs: 'auto', md: '1216px' },
              backgroundColor: '#A7CEFC33',
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              alignContent: 'center',
              justifyContent: 'space-evenly',
              py: { xs: '40px', md: '55px' },
              px: { xs: '32px', md: '0' },
              borderRadius: '16px',
              gap: { xs: '32px', md: '0' },
            }}
          >
            {matches && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '16px',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '30px',
                    lineHeight: '38px',
                    textAlign: 'center',
                    color: '#42307D',
                  }}
                >
                  Unleash the full power of AI and Analytics
                </Typography>

                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '18px',
                    lineHeight: '28px',
                    textAlign: 'Center',
                    color: '#475467',
                  }}
                >
                  Everything you need to monitor, engage and increase
                  productivity
                </Typography>
              </Box>
            )}
            <Box
              sx={{
                height: '112px',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '48px', md: '60px'},
                  lineHeight: { xs: '60px', md: '72px'},
                  letterSpacing: { xs: '-0.96px', md: '-1.2px'},
                  textAlign: 'Center',
                  color: '#4F9CF9',
                }}
              >
                400+
              </Typography>

              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '28px',
                  textAlign: 'Center',
                  color: { xs: '#42307D', md: '#4F9CF9' },
                }}
              >
                Projects completed
              </Typography>
            </Box>

            <Box
              sx={{
                height: '112px',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '48px', md: '60px'},
                  lineHeight: { xs: '60px', md: '72px'},
                  letterSpacing: { xs: '-0.96px', md: '-1.2px'},
                  textAlign: 'Center',
                  color: '#4F9CF9',
                }}
              >
                600%
              </Typography>

              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '28px',
                  textAlign: 'Center',
                  color: { xs: '#42307D', md: '#4F9CF9' },
                }}
              >
                Return on investment
              </Typography>
            </Box>

            <Box
              sx={{
                height: '112px',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                justifyContent: 'center',
                gap: '12px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '48px', md: '60px'},
                  lineHeight: { xs: '60px', md: '72px'},
                  letterSpacing: { xs: '-0.96px', md: '-1.2px'},
                  textAlign: 'Center',
                  color: '#4F9CF9',
                }}
              >
                10k
              </Typography>

              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: '18px',
                  lineHeight: '28px',
                  textAlign: 'Center',
                  color: { xs: '#42307D', md: '#4F9CF9' },
                }}
              >
                Global downloads
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default SixthSection;
