import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import CustomText from 'store/General/CustomText';

const SeventhSection = () => {

  const theme = useTheme();
  
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <Box>
      <Box sx={{
        px: { xs: '0px', md: '32px'},
        width: { xs: 'auto', md: '1280px'},
        alignItems: 'flex-start',
        marginTop: { xs: '64px', md: '96px'},
      }}>
        <CustomText text="Features" textStyles={{
          color: 'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
          fontSize: '16px',
          lineHeight: '24px',
        }}/>
        <CustomText text="Why Choose Ezaix" textStyles={{
          margin: { xs: '12px 0 0 0px', md: '12px 0px 20px 0px' },
          fontSize: { xs: '30px', md: '36px'},
          lineHeight: { xs: '38px', md: '44px'},
        }}/>
        {!matches && (
          <Typography
          sx={{
            alignSelf: 'stretch',
            fontWeight: '400',
            fontSize: '20px',
            lineHeight: '30px',
            color: '#475467',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            textAlign: 'center',
          }}
        >
          Welcome to 
          <Typography
            component="span"
            sx={{
              alignSelf: 'stretch',
              fontWeight: '600',
              fontSize: '20px',
              lineHeight: '30px',
              color: '#475467',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              textAlign: 'center',
            }}
          >
            {" Ezaix"}
          </Typography>
          : Pioneering Future-Ready Enterprise Solutions
        </Typography>
        )}
      </Box>
    </Box>
  )
}

export default SeventhSection;