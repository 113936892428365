import { Box } from '@mui/material';
import React, { useState } from 'react';
import Header from 'store/Header';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import BackgroundPattern from 'store/images/Blog/background-pattern.png';

const Blogs = () => {
  const [searchTerm, setSearchTerm] = useState('');
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <Header />
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            pt: '96px',
            background: 'rgba(3, 6, 76, 0.15)',
          }}
        >
          <FirstSection onSearchTermChange={setSearchTerm} />
        </Box>
        <Box
          sx={{
            backgroundImage: `url(${BackgroundPattern})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100% auto',
          }}
        >
          <SecondSection searchTerm={searchTerm} />
        </Box>
      </Box>
    </Box>
  );
};

export default Blogs;
