import { Box, Typography, Button } from '@mui/material';
import { PARTNER_SECOND } from 'constants/partner';
import CustomText from 'store/CustomText';

function SecondSection() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: { xs: '100%', md: '1440px' },
          // height: '766px',
          padding: { xs: '64px 0px', md: '96px 0px'},
          boxSizing: 'border-box',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: '48px', md: '64px' },
          background: 'var(--Base-White, #FFF)',
          marginX: 'auto',
        }}
      >
        <Box
          sx={{
            color: 'var(--Gray-900, #101828)',
            textAlign: 'center',
            padding: { xs: '0 16px', md: '0 32px' },
            boxSizing: 'border-box',
            fontFamily: 'Inter',
            fontSize: '36px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '44px',
            letterSpacing: '-0.72px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '48px',
              alignSelf: 'stretch',
            }}
          >
            <Typography
              sx={{
                color: 'var(--Gray-900, #101828)',
                textAlign: 'center',

                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: { xs: '30px', md: '36px'},
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: {xs: '38px', md: '44px'} /* 122.222% */,
                letterSpacing: '-0.72px',
              }}
            >
              Why become a Ezaix partner?
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            width: { xs: '100%', md: '1280px' },
            boxSizing: 'border-box',
            padding: { xs: '0 16px', md: '0px 32px' },
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '64px',
          }}
        >
          <Box sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            gap: { xs: '24px', md: '32px'},
            marginTop: '32px',
          }}>
            {PARTNER_SECOND.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'fit-content',
                  width: { xs: '100%', md: '384px' },
                  padding: { xs: '68px 0', md: '78px 24px 58px 24px'},
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: '16px',
                  background: ' var(--Gray-50, #F9FAFB)',
                  position: 'relative',
                  margin: item.id === '2' ? {xs: '24px 0', md: '49px 0 0 0'} : '0',
                }}
              >
                <CustomText
                  text={item.id}
                  textStyles={{
                    color: '#4F9CF9',
                    fontSize: '80px',
                    fontWeight: '800',
                    position: 'absolute',
                    top: '-25px',
                    left: '0',
                    right: '0',
                  }}
                />
                <CustomText 
                  text={item.title}
                  textStyles={{
                    fontSize: '20px',
                    lineHeight: '30px',
                    marginBottom: '8px',
                  }}
                />
                <CustomText 
                  text={item.desc}
                  textStyles={{
                    color: '#475467',
                    fontSize: { xs: '14px', md: '16px'},
                    fontWeight: '400',
                    lineHeight: '24px',
                  }}
                />
              </Box>
            ))}
          </Box>
        </Box>

        <Button
          variant="contained"
          color="secondary"
          sx={{
            display: 'flex',
            width: { xs: '90%', md: 'fit-content'},
            padding: { xs: '12px 20px', md: '16px 28px'},
            justifyContent: 'center',
            alignItems: 'center',
            gap: '12px',
            borderRadius: '8px',

            /* Shadow/xs */
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
            textTransform: 'none',
          }}
        >
          <Typography
            sx={{
              color: 'var(--Base-White, #FFF)',
              /* Text lg/Semibold */
              fontFamily: 'Inter',
              fontSize: { xs: '16px', md: '18px'},
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: { xs: '24px', md: '28px'},
            }}
          >
            Become a Ezaix Partner
          </Typography>
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          width: { xs: '100%', md: '1280px' },
          padding: { xs: '0 16px', md: '0px 32px' },
          boxSizing: 'border-box',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // height: '339px',
            width: { xs: '100%', md: '1216px' },
            padding: { xs: '64px 0', md: '64px' },
            boxSizing: 'border-box',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '32px',
            borderRadius: '16px',
            background: 'var(--Gray-50, #F9FAFB)',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', md: '905px' },
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: { xs: '16px', md: '32px' },
              textAlign: 'center',
            }}
          >
            <Typography
              sx={{
                color: 'var(--Gray-900, #101828)',

                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: '30px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '38px' /* 126.667% */,
              }}
            >
              Who Benefits from a Partnership with Ezaix?
            </Typography>
            <Typography
              sx={{
                color: 'var(--Gray-600, #475467)',

                /* Display md/Semibold */
                fontFamily: 'Inter',
                fontSize: { xs: '18px', md: '20px' },
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: { xs: '28px', md: '30px' },
                width: { xs: '100%', md: '903' },
              }}
            >
              Whether you're a dynamic startup, a growing SME, or a large
              enterprise, our solutions are designed to bring transformative
              results. Our partnerships span various sectors, demonstrating the
              versatility and impact of our technologies across industries.
            </Typography>
          </Box>
          {/* <Button
            variant="contained"
            color="secondary"
            sx={{
              display: 'flex',
              padding: { xs: '12px 20px', md: '16px 28px' },
              height: '52px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '12px',
              borderRadius: '8px',
              border: '1px solid var(--Primary-Buttons, #4F9CF9)',

              
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              textTransform: 'none',
              width: { xs: '100%', md: 'fit-content' },
            }}
          >
            <Typography
              sx={{
                color: 'var(--Base-White, #FFF)',
                
                fontFamily: 'Inter',
                fontSize: { xs: '16px', md: '18px' },
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: { xs: '24px', md: '28px' },
              }}
            >
              Learn More
            </Typography>
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
}

export default SecondSection;
