import React from 'react'
import CustomText from '../CustomText'

const Label = ({label}) => {
  return (
    <CustomText text={label} textStyles={{
      color: '#344054',
      fontSize: '14px',
      fontFamily: 'Inter',
      lineHeight: '20px',
      fontWeight: 500,
      textAlign: 'left',
      marginBottom: '6px',
    }}/>
  )
}

export default Label;