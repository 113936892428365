import { Box, Button, Typography } from '@mui/material';

const SectionSix = () => {

  const handleNavigation = (link) => {
    window.location.href = link; 
  };

  return (
    <>
      <Box
        sx={{
          background: 'var(--Base-White, #FFF)',
          display: 'flex',
          padding: { xs: '48px 0', md: '96px 0px' },
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          alignSelf: 'stretch',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            width: { xs: '100%', md: '1280px' },
            boxSizing: 'border-box',
            padding: { xs: '0 16px', md: '0px 32px' },
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '32px',
          }}
        >
          <Box
            sx={{
              borderRadius: '16px',
              background:
                'var(--gradient, linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%))',
              display: 'flex',
              padding: {xs: '64px 32px', md: '64px'},
              flexDirection: 'column',
              alignItems: 'center',
              gap: '32px',
              alignSelf: 'stretch',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: { xs: '100%', md: '595px' },
                flexDirection: 'column',
                alignItems: 'center',
                gap: { xs: '16px', md: '20px' },
              }}
            >
              <Typography
                sx={{
                  color: 'var(--Base-White, #FFF)',
                  fontFamily: 'Inter',
                  fontSize: '36px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '44px',
                  letterSpacing: '-0.72px',
                  textAlign: 'center',
                }}
              >
                Transform Your Business with Ezaix
              </Typography>
              <Typography
                sx={{
                  width: { xs: '100%', md: '836px' },
                  color: 'var(--Primary-200, #E9D7FE)',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '30px',
                }}
              >
                Discover how our analytics and provisioning solutions can
                revolutionize your business operations. Embrace the power of
                Azure-powered technology to unlock new levels of efficiency,
                insight, and growth.
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="tertiary"
              onClick={() => handleNavigation('/contact')}
              sx={{
                borderRadius: '8px',
                background: 'var(--Primary-Buttons, #4F9CF9)',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                display: 'flex',
                padding: '12px 20px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                textTransform: 'none',
                width: { xs: '100%', md: 'fit-content' },
              }}
            >
              <Typography
                sx={{
                  color: 'var(--Base-White, #FFF)',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '24px',
                }}
              >
                Get in Touch
              </Typography>
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SectionSix;
