import React from 'react';
import { Box } from '@mui/material';
import { red } from '@mui/material/colors';
import Header from 'store/Header';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import FourthSection from './FourthSection';
import FifthSection from './FifthSection';
import PartnerShipJourney from './PartnerShipJourney';
import Footer from 'store/Footer';

function Partner() {
  return (
    <Box
      sx={{
        width: '100vw',
        overflow: 'hidden',
      }}
    >
      {/* every one has to have width: 80% and margin auto or add class name contentBox */}

      <Box
        sx={{
          width: '100%',
          // height: '1116px',
          backgroundImage:
            'linear-gradient(38.02deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Header backgroundIsPresent={true} />

        <FirstSection />
      </Box>

      <SecondSection />
      <PartnerShipJourney />
      <ThirdSection />
      {/* <FourthSection /> */}
      <FifthSection />
    </Box>
  );
}

export default Partner;
