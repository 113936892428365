import { Box } from '@mui/material'
import React from 'react'
import Header from 'store/Header';
import FirstSection from './FirstSection';
import SecondSection from './SecondSection';
import ThirdSection from './ThirdSection';
import ContentSection from './ContentSection';

const AboutUs = () => {
  return (
    <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Header />
        <Box className="contentBox" sx={{display: 'flex', flexDirection: 'column', py: {
          xs: '128px', md: '192px'
        }}} gap='64px'>
          <FirstSection />
          <SecondSection />
          <ContentSection />
          <ThirdSection />
        </Box>
      </Box>
  )
}

export default AboutUs;