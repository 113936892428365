import { Box } from '@mui/material';

import { ReactComponent as MapWrap } from 'store/images/ContactUs/map-wrap.svg';
import { CONTACT_LEFT_SECTION } from 'constants/contact';
import CustomText from 'store/General/CustomText';

const LeftSection = () => {
  return (
    <Box
      sx={{
        width: '789px',
        paddingY: '138px',
        borderRight: '1px solid rgba(0, 0, 0, 0.25)',
      }}
    >
      <MapWrap style={{ width: '685px', height: '488px' }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: '32px',
          padding: '0 32px 96px 32px',
          marginTop: '64px',
          justifyContent: 'center',
        }}
      >
        {CONTACT_LEFT_SECTION.map((item, index) => (
          <Box key={index} sx={{ flex: 1 }}>
            <CustomText
              text={item.title}
              textStyles={{
                fontSize: '20px',
                lineHeight: '30px',
              }}
            />
            <CustomText
              text={item.desc}
              textStyles={{
                color: '#475467',
                fontSize: '16px',
                lineHeight: '24px',
                fontWeight: 400,
                margin: '8px 0 20px 0',
              }}
            />
            <CustomText
              text={item.contact}
              textStyles={{
                background:
                  'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: '16px',
                lineHeight: '24px',
              }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default LeftSection;
