import { Box } from '@mui/material'
import React from 'react';
import CustomText from 'store/General/CustomText';
import MiniTitle from 'store/General/MiniTitle';
import SearchBar from 'store/General/SearchBar';

const FirstSection = ({onSearchTermChange}) => {

  const textStyle = {
    textDecoration: 'none',
    background: 'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
    backgroundClip: "text",
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
  }

  return (
   <Box sx={{alignSelf: 'center', display: 'flex', flexDirection: 'column', justifyContent:'center'}}>
    <Box sx={{display: 'flex', flexDirection: 'column', justifyContent:'center', maxWidth: 'fit-content'}}>
     <MiniTitle title='Our blog' styles={{alignSelf: 'center'}}/>
     <CustomText
      text='Resources and insights'
      textStyles={{
        fontSize: '48px',
        lineHeight: '60px',
        letterSpacing: '-0.96px',
        margin: '16px 0 24px 0',
        ...textStyle
      }}
     />
     <CustomText
      text='The latest industry news, interviews, technologies, and resources.'
      textStyles={{
        fontSize: '20px',
        lineHeight: '30px',
        fontWeight: '400',
        ...textStyle
      }}
     />
   </Box>
    <SearchBar onSearch={onSearchTermChange}/>
    </Box>
  )
}

export default FirstSection