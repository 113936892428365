import XIcon from '@mui/icons-material/X';
import { ReactComponent as DribbbleLogo } from 'store/Logos/icons/dribbble.svg';
import { ReactComponent as AngelListLogo } from 'store/Logos/icons/angel-list.svg';
import { ReactComponent as GitHubIcon } from 'store/Logos/icons/github.svg';
import { ReactComponent as FacebookIcon } from 'store/Logos/icons/facebook.svg';
import { ReactComponent as LinkedInIcon } from 'store/Logos/icons/linkedin.svg';
import { ReactComponent as TwitterIcon } from 'store/Logos/icons/twitter.svg';
import YouTubeIcon from '@mui/icons-material/YouTube';

export const FOOTER_INFO = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: "About Us",
    link: '/aboutUs',
  },
  {
    title: "Our Solutions",
    link: '/solution',
  },
  {
    title: "Partner With Us",
    link: '/partner',
  },
  {
    title: "Privacy Policy",
    link: '/privacy-policy',
  }
];

export const FOOTER_SOCIAL_LINKS = [
  {
    icon: <TwitterIcon sx={{width: '24px', height: '24px', color: 'rgba(152, 162, 179, 1)'}}/>,
    link: 'https://twitter.com/EzaixInc',
  },
  {
    icon: <LinkedInIcon sx={{width: '24px', height: '24px', color: 'rgba(152, 162, 179, 1)'}}/>,
    link: 'https://www.linkedin.com/company/ezaix-inc',
  },
  {
    icon: <YouTubeIcon viewBox='2 3 20 20' sx={{
      width: '28px',
      height: '28px',
      color: 'rgb(208, 213, 221)',
    }}/>,
    link: 'https://www.youtube.com/@EzaixSocial',
  },
  // {
  //   icon: <GitHubIcon sx={{width: '24px', height: '24px', color: 'rgba(152, 162, 179, 1)'}}/>,
  //   link: '#',
  // },
  // {
  //   icon: <AngelListLogo sx={{width: '24px', height: '24px', color: 'rgba(152, 162, 179, 1)'}}/>,
  //   link: '#',
  // },
  // {
  //   icon: <DribbbleLogo sx={{width: '24px', height: '24px', color: 'rgba(152, 162, 179, 1)'}}/>,
  //   link: '#',
  // },
];

export const FOOTER_POLICIES = [
  {
    title: 'Terms',
    link: '#',
  },
  {
    title: 'Privacy',
    link: '#',
  },
  {
    title: 'Cookies',
    link: '#',
  }
]