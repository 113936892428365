import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomText from 'store/CustomText';
import Header from 'store/Header';

const PrivacyPolicy = () => {
    return (
        <Box
            sx={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
            }}
        >
            <Header />
            <Box className="contentBox"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    py: {
                        xs: '64px', md: '96px'
                    },
                    maxWidth: '830px',
                    my: '95px'
                }}
                gap='54px'
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Typography
                        sx={{
                            fontSize: '16px',
                            fontWeight: '600',
                            letterSpacing: '-0.72px',
                            color: '#02096E',
                            fontFamily: 'Inter',
                            alignSelf: 'center',
                            mb: '5px'
                        }}
                    >
                        Current as of 20 Jan 2024
                    </Typography>

                    <Typography
                        sx={{
                            fontSize: '48px', 
                            fontWeight: '600', 
                            letterSpacing: '-0.72px',
                            color: '#101828',
                            fontFamily: 'Inter',
                            alignSelf: 'center'
                        }}
                    >
                        Privacy Policy
                    </Typography>

                </Box>


                <CustomText textStyles={{
                    fontSize: '20px',
                    lineHeight: '30px',
                    fontWeight: '400',
                    color: '#475467',
                    textAlign: 'center',
                }} text='Your privacy is important to us at Ezaix. We respect your privacy regarding any information we may collect from you across our website.' />





                <CustomText textStyles={{
                    fontSize: '20px',
                    lineHeight: '30px',
                    fontWeight: '400',
                    color: '#475467',
                    textAlign: 'left',
                }} text='Welcome to EZAIX. Your privacy and trust are important to us and this Privacy Policy provides important information about how EZAIX and its affiliated companies (collectively, "EZAIX", "we", "us", or "our") handle personal information. This policy applies to any EZAIX website, application, product, software, or service that links to this policy (collectively, our “Services”).
                If you choose to use our Service, then you agree to the collection and use of information in relation with this policy. The Personal Information that we collect are used for providing and improving the Service. We will not use or share your information with anyone except as described in this Privacy Policy.'  />

                <Typography
                    sx={{
                        fontSize: '18px', fontWeight: '400', letterSpacing: '-0.72px',
                        color: '#475467',
                        fontFamily: 'Inter',
                    }}>
                    <ol>
                        <li>
                            <Typography
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    color: '#101828'
                                }}
                            >
                                Information We Collect We collect information to provide better services to all our users. The types of information we collect include:
                            </Typography>
                        </li>

                        <ul style={{ marginBottom: '1rem', listStyle: 'disc' }}>
                            <li><strong>Personal Information:</strong> Your name, email address, phone number, and other contact data.</li>
                            <li><strong>Credentials: </strong>Passwords, password hints, and similar security information used for authentication and account access.</li>
                            <li><strong>Technical Information:</strong> Details about your hardware, software, and other technical information related to your interactions with our Services.</li>
                            <li><strong>Usage Data:</strong> Your interactions with our services, including, but not limited to, telephony data like call logs, call quality, and device information.</li>
                            <li><strong>Location Information:</strong> Geographic location data when you use relevant features.</li>
                        </ul>

                        <li>
                            <Typography
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    color: '#101828'
                                }}
                            >
                                How We Use Information We use the information we collect to operate our business, which includes analyzing our performance, meeting our legal obligations, developing our workforce, and doing research. Specifically, we use information:
                            </Typography>
                        </li>

                        <ul style={{ margin: '1rem 0', listStyle: 'disc' }}>
                            <li>To provide our services and facilitate communication.</li>
                            <li>To protect the safety and security of our Services.</li>
                            <li>To communicate with you, including sending you updates, information about products or services, and marketing offers.</li>
                            <li>To improve and develop our products and services.</li>
                            <li>To conduct analytics and perform research.</li>
                        </ul>

                        <li>
                            <Typography
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    color: '#101828'
                                }}
                            >
                                Information Sharing and Disclosure We do not share personal information with companies, organizations, or individuals outside of EZAIX except in the following cases:
                            </Typography>
                        </li>


                        <ul style={{ margin: '1rem 0', listStyle: 'disc' }}>
                            <li><strong>With your consent.</strong></li>
                            <li><strong>For external processing:</strong> We provide personal information to our affiliates or other trusted businesses or persons to process it for us, based on our instructions and in compliance with our business policies and procedures.</li>
                        </ul>

                        <li style={{ margin: '1rem 0' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    color: '#101828'
                                }}
                            >
                                Security
                            </Typography>
                        </li>

                        We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.

                        <li style={{ margin: '1rem 0' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    color: '#101828'
                                }}
                            >
                                Children's Privacy
                            </Typography>
                        </li>
                        Our Services do not address anyone under the age of 13. We do not knowingly collect personal identifiable information from children under 13. In the case we discover that a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.

                        <li style={{ margin: '1rem 0' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    color: '#101828'
                                }}
                            >Changes to This Privacy Policy</Typography>
                        </li>
                        We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.

                        <li style={{ margin: '1rem 0' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Inter',
                                    fontWeight: '700',
                                    fontSize: '24px',
                                    color: '#101828'
                                }}
                            >How can you contact us about this policy?</Typography>
                        </li>
                        If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.
                    </ol>
                </Typography>
            </Box>
        </Box>
    )
}

export default PrivacyPolicy;