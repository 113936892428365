import React from 'react';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button } from '@mui/material';
import CustomText from 'store/CustomText';

const DrawerLogic = ({ open, onClose }) => {
  const handleNavigation = (link) => {
    window.location.href = link;
  };

  const buttonStyle = {
    padding: '0',
    textTransform: 'none',
    minWidth: 'auto',
  };

  const textStyle = {
    fontSize: '16px',
    fontFamily: 'Noto Sans',
    lineHeight: '26.667px',
    textDecoration: 'none',
    color: '#475467',
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      sx={{
        '& .MuiDrawer-paper': {
          width: '80%',
          padding: '31px 16px 18px 16px',
        },
      }}
    >
      <Button
        variant="contained"
        color="secondary"
        onClick={onClose}
        sx={{
          minWidth: '37px',
          maxWidth: 'fit-content',
          minHeight: 'auto',
          height: '35px',
          borderRadius: '4px',
          padding: '0',
          marginBottom: '28px',
          alignSelf: 'flex-end',
        }}
      >
        <CloseIcon
          sx={{
            color: 'white',
          }}
        />
      </Button>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: { xs: '80%', md: '85%' },
          gap: '24px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '24px',
          }}
        >
          <Button onClick={() => handleNavigation('/')} sx={buttonStyle}>
            <CustomText text="Home" textStyles={textStyle} />
          </Button>
          <Button onClick={() => handleNavigation('/aboutUs')} sx={buttonStyle}>
            <CustomText text="About Us" textStyles={textStyle} />
          </Button>
          <Button onClick={() => handleNavigation('/solution')} sx={buttonStyle}>
            <CustomText text="Solutions" textStyles={textStyle} />
          </Button>
          <Button onClick={() => handleNavigation('/partner')} sx={buttonStyle}>
            <CustomText text="Partner With us" textStyles={textStyle} />
          </Button>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '12px',
          }}
        >
          <Button
            variant="text"
            onClick={() => handleNavigation('/contact')}
            color="inherit"
            sx={{
              textTransform: 'none',
              padding: '10px 18px',
              borderRadius: '8px',
              width: '100%',
            }}
          >
            <CustomText
              text="Get Started"
              textStyles={{
                fontSize: '16px',
                lineHeight: '24px',
                color: textStyle,
              }}
            />
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleNavigation('/contact')}
            sx={{
              textTransform: 'none',
              padding: '10px 18px',
              borderRadius: '8px',
              width: '100%',
            }}
          >
            <CustomText
              text="Schedule a Call"
              textStyles={{
                fontSize: '16px',
                lineHeight: '24px',
                color: 'white',
              }}
            />
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DrawerLogic;
