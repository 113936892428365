import { Box, Button, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import CustomText from 'store/General/CustomText';
import InputField from 'store/General/InputField';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextAreaField from 'store/General/TextArea';
import Label from 'store/General/Label';

import CheckBox from 'store/General/CheckBox';
import { MuiTelInput } from 'mui-tel-input';

import Matches from 'store/matches';
import { CONTACT_LEFT_SECTION } from 'constants/contact';
import AlertBox from 'store/AlertBox';

const RightSection = () => {
  const matches = Matches();
  const [alertInfo, setAlertInfo] = useState({ show: false, status: '' });
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
    legal: {
      privacyPolicy: false,
    },
    services: {
      privacyPolicy: false,
      strategyConsulting: false,
      userResearch: false,
    },
  });
  const { phoneNumber } = formValues;

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };
  const handlePhoneChange = (newPhone) => {
    setFormValues({
      ...formValues,
      phoneNumber: newPhone,
    });
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormValues({
      ...formValues,
      legal: {
        ...formValues.legal,
        [name]: checked,
      },
      services: {
        ...formValues.services,
        [name]: checked,
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Combine first name and last name to create the full name
    const fullName = `${formValues.firstName} ${formValues.lastName}`;
  
    // Set up the data to be sent to the PHP script
    const postData = new FormData();
    postData.append('name', fullName);
    postData.append('email', formValues.email);
    postData.append('message', formValues.message);
  
    // Use Axios to send a POST request
    axios({
      method: 'post',
      url: 'http://ezaix.com/test_post.php', // Replace with the actual URL to your script
      data: postData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    .then(function (response) {
      console.log(response);
      setAlertInfo({ show: true, status: 'success' });
    })
    .catch(function (error) {
      setAlertInfo({ show: true, status: 'error' });
    });
  };

  const textStyles = {
    '& .MuiTypography-root': {
      color: '#344054',
      fontSize: '16px',
      fontWeight: 500,
      fontFamily: 'Inter',
      fontStyle: 'normal',
      lineHeight: '24px',
      alignSelf: 'center',
    },
  };

  return (
    <>
    <Box
      sx={{
        padding: { xs: '128px 0 64px 0px', md: '122px 0 96px 0' },
        width: { xs: '100%', md: '651px' },
      }}
      component="form"
      onSubmit={handleSubmit}
    >
      <Box
        sx={{
          marginX: {
            xs: '16px',
            md: '69.5px',
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Box>
          <CustomText
            text="We’d love to hear from you"
            textStyles={{
              fontSize: { xs: '36px', md: '40px' },
              lineHeight: { xs: '44px', md: '60px' },
              letterSpacing: { xs: '-0.72px', md: '-0.8px' },
              textAlign: { xs: 'left', sm: 'center' },
            }}
          />
          <Typography
            sx={{
              fontSize: { xs: '18px', md: '20px' },
              lineHeight: { xs: '28px', md: '30px' },
              color: '#475467',
              fontWeight: 400,
              margin: '24px 0 48px 0',
              textAlign: { xs: 'left', sm: 'center'},
            }}
          >
            You can reach us anytime via{' '}
            <span
              style={{
                background:
                  'linear-gradient(38deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                fontSize: '20px',
                lineHeight: '30px',
                fontWeight: 500,
              }}
            >
              info@ezaix.com
            </span>
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '24px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                gap: { xs: '24px', md: '32px' },
              }}
            >
              <InputField
                name="firstName"
                label="First name"
                placeholder="First name"
                value={formValues.firstName}
                onChange={handleInputChange}
              />
              <InputField
                name="lastName"
                label="Last name"
                placeholder="Last name"
                value={formValues.lastName}
                onChange={handleInputChange}
              />
            </Box>
            <InputField
              name="email"
              label="Email"
              placeholder="you@company.com"
              value={formValues.email}
              onChange={handleInputChange}
            />
            <Box>
              <Label label="Phone number" />
              <MuiTelInput
                value={phoneNumber}
                defaultCountry="US"
                forceCallingCode={true}
                onChange={handlePhoneChange}
                sx={{
                  width: '100%',
                  '& .MuiInputBase-root': {
                    borderRadius: '8px',
                  },
                }}
              />
            </Box>
            <TextAreaField
              name="message"
              label="Message"
              onChange={handleInputChange}
              value={formValues.message}
            />
            {!matches ? (
              // <Box>
              //   <Label label="Services" />
              //   <FormGroup>
              //     <FormControlLabel
              //       control={
              //         <CheckBox
              //           name="privacyPolicy"
              //           onChange={handleCheckboxChange}
              //         />
              //       }
              //       label="Product demo"
              //       sx={textStyles}
              //     />
              //     <FormControlLabel
              //       control={
              //         <CheckBox
              //           name="strategyConsulting"
              //           onChange={handleCheckboxChange}
              //         />
              //       }
              //       label="Strategy & consulting"
              //       sx={textStyles}
              //     />
              //     <FormControlLabel
              //       control={
              //         <CheckBox
              //           name="userResearch"
              //           onChange={handleCheckboxChange}
              //         />
              //       }
              //       label="User research"
              //       sx={textStyles}
              //     />
              //   </FormGroup>
              // </Box>
              ""
            ) : (
              <FormGroup>
                <FormControlLabel
                  control={
                    <CheckBox
                      name="privacyPolicy"
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="You agree to our friendly privacy policy"
                  sx={{
                    color: '#475467',
                    fontSize: '16px',
                    fontWeight: 400,
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    lineHeight: '24px',
                    marginBottom: '32px',
                  }}
                />
              </FormGroup>
            )}
          </Box>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
          sx={{
            textTransform: 'none',
            padding: '12px 20px',
            borderRadius: '8px',
            alignSelf: 'center',
            width: '100%',
            marginTop: { xs: '0', md: '32px' },
            marginBottom: { xs: '64px', md: '0' },
          }}
        >
          <CustomText
            text="Send message"
            textStyles={{
              fontSize: '16px',
              lineHeight: '24px',
              color: 'white',
            }}
          />
        </Button>
        {matches && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: '40px',
              justifyContent: 'center',
            }}
          >
            {CONTACT_LEFT_SECTION.map((item, index) => (
              <Box key={index} sx={{ flex: 1 }}>
                <CustomText
                  text={item.title}
                  textStyles={{
                    fontSize: '18px',
                    lineHeight: '28px',
                  }}
                />
                <CustomText
                  text={item.desc}
                  textStyles={{
                    color: '#475467',
                    fontSize: '16px',
                    lineHeight: '24px',
                    fontWeight: 400,
                    margin: '4px 0 16px 0',
                  }}
                />
                <CustomText
                  text={item.contact}
                  textStyles={{
                    color: '#6941C6',
                    fontSize: '16px',
                    lineHeight: '24px',
                  }}
                />
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
    <AlertBox open={alertInfo.show} handleClose={() => setAlertInfo({ ...alertInfo, show: false })} status={alertInfo.status} />
    </>
  );
};

export default RightSection;
