export const ABOUTUS_SECOND = {
  firstBox: [
    {
      desc: [
        "zaix stands at the forefront of AI-powered automation solutions, leveraging the robust capabilities of Microsoft Azure to drive innovation and efficiency across businesses. As a leading solutions partner, Ezaix is dedicated to harnessing the power of Azure, Azure DevOps, Azure OpenAI, and the Microsoft Graph API to transform the way businesses operate, ensuring they stay ahead in today’s fast-paced digital landscape."
      ],
      descStart: "E",
    }
  ],

  secondBox: [
    {
      desc: "Ezaix proudly partners with leading organizations in the Education and Finance sectors, demonstrating our unparalleled expertise in deploying AI, chatbots, and automation solutions powered by Microsoft Azure. Our tailored solutions are revolutionizing these industries, offering innovative ways to engage with users, automate processes, and leverage data for actionable insights."      
    }
  ]
}

export const ABOUTUS_THIRD = [
  {
    title: '40+',
    desc1: 'Companies Served',
    desc2: 'We’ve helped build over 40 projects with great companies.'
  },
  {
    title: '600%',
    desc1: 'Return on investment',
    desc2: 'We’ve helped build over 400 projects with great companies.'
  },
  {
    title: '120+',
    desc1: 'Hours Saved',
    desc2: 'Due to increase in productivity, we saved upto 120+ hours.'
  }
]