import React from 'react';
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ReactComponent as HeroImage } from 'store/images/partnership_hero.svg';

function FirstSection() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      {/* every one has to have width: 80% and margin auto or add class name contentBox */}

      <Box
        sx={{
          width: '100%',
          height: 'auto',
          backgroundImage:
            'linear-gradient(38.02deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            padding: { xs: '128px 0 64px 0', md: '192px 0 129px 0'},
            flexDirection: 'column',
            alignItems: 'center',
            gap: '64px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row'},
              alignItems: 'flex-start',
              gap: { xs: '32px', md: '64px' },
              flex: '1 0 0',
              padding: { xs: '0 16px', md: '0 32px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
                alignSelf: 'stretch',
              }}
            >
              {!matches && <Box
                sx={{
                  display: 'flex' ,
                  padding: '4px 10px 4px 4px',
                  alignItems: 'center',
                  gap: '12px',
                  borderRadius: '16px',
                  border: '1.5px solid rgba(255, 255, 255, 0.80)',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    padding: '2px 10px',
                    alignItems: 'center',
                    borderRadius: '16px',
                    border: '1.5px solid rgba(255, 255, 249, 0.80)',
                  }}
                >
                  <Typography
                    sx={{
                      color: 'rgba(255, 255, 255, 0.80)',
                      textAlign: 'center',

                      /* Text md/Medium */
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '20px' /* 142.857% */,
                    }}
                  >
                    Partnership
                  </Typography>
                </Box>

                <Typography
                  sx={{
                    color: 'rgba(255, 255, 255, 0.80)',

                    /* Text md/Medium */
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '20px' /* 142.857% */,
                  }}
                >
                  Core expertise in AI and Analytics
                </Typography>

                <Box
                  sx={{
                    width: '16px',
                    height: '16px',
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M3.3335 8.00016H12.6668M12.6668 8.00016L8.00016 3.3335M12.6668 8.00016L8.00016 12.6668"
                      stroke="white"
                      stroke-opacity="0.8"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Box>
              </Box>}
              <Box
                sx={{
                  width: {xs: "100%", md: '570px'},
                }}
              >
                <Typography
                  sx={{
                    color: '#FFF',

                    /* Display xl/Semibold */
                    fontFamily: 'Inter',
                    fontSize:{xs: "36px", md: '60px'},
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: {xs: "44px", md:'72px' },
                    letterSpacing:{xs: "-.72px", md: '-1.2px'},
                    textAlign: {xs: 'center', md: 'left'},
                  }}
                >
                  Empowering Innovation Together
                </Typography>
              </Box>

              <Box
                sx={{
                  width: {xs: "100%", md: '559px'},
                  height: {xs: "auto", md:'255px'},
                }}
              >
                <Typography
                  sx={{
                    color: 'rgba(255, 255, 255, 0.80)',

                    /* Text xl/Regular */
                    fontFamily: 'Inter',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '30px' /* 150% */,
                    textAlign: {xs: 'center', md: 'left'},
                  }}
                >
                  In the rapidly evolving digital landscape, the power of
                  collaboration cannot be overstated. We believe in fostering
                  strong partnerships that drive mutual growth, innovation, and
                  success.
                  <br />
                  <br />
                  Our mission extends beyond delivering cutting-edge enterprise
                  solutions; we aim to build lasting relationships that empower
                  our partners to achieve and exceed their strategic objectives.
                </Typography>
              </Box>
              <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '12px',
                alignSelf: 'stretch',
                marginTop: {xs: '32px', md: '48px'},
              }}
            >
              {/* <Button
                variant="contained"
                color="secondary"
                component={NavLink}
                to="/solution"
                sx={{
                  display: 'flex',
                  padding: '16px 28px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: { xs: '100%', md: 'auto' },
                  gap: '12px',
                  borderRadius: '8px',
                  border: '1px solid var(--Primary-Buttons, #4F9CF9)',

                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  textTransform: 'none',
                }}
              >
                <Typography
                  sx={{
                    color: 'var(--Base-White, #FFF)',
                    fontFamily: 'Inter',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '28px',
                  }}
                >
                  Become a Ezaix Partner
                </Typography>
              </Button> */}
              {matches && (
                <Button
                  variant="contained"
                  color="tertiary"
                  sx={{
                    display: 'flex',
                    padding: '16px 28px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: { xs: '100%', md: 'auto' },
                    gap: '12px',
                    borderRadius: '8px',
                    background: ' var(--Base-White, #FFF)',
                    border: '1px solid var(--Primary-Buttons, #4F9CF9)',

                    /* Shadow/xs */
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                    gap: '8px',
                    alignItems: 'center',
                    textTransform: 'none',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '24px',
                      height: '24px',
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_346_7849)">
                        <path
                          d="M9.99984 18.3332C14.6022 18.3332 18.3332 14.6022 18.3332 9.99984C18.3332 5.39746 14.6022 1.6665 9.99984 1.6665C5.39746 1.6665 1.6665 5.39746 1.6665 9.99984C1.6665 14.6022 5.39746 18.3332 9.99984 18.3332Z"
                          stroke="#344054"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.9165 7.47095C7.9165 7.07321 7.9165 6.87434 7.99962 6.76332C8.07206 6.66657 8.18293 6.60603 8.30349 6.59742C8.44182 6.58754 8.60911 6.69508 8.94368 6.91016L12.8775 9.43905C13.1678 9.62569 13.313 9.719 13.3631 9.83766C13.4069 9.94134 13.4069 10.0583 13.3631 10.162C13.313 10.2807 13.1678 10.374 12.8775 10.5606L8.94368 13.0895C8.60911 13.3046 8.44182 13.4121 8.30349 13.4023C8.18293 13.3936 8.07206 13.3331 7.99962 13.2364C7.9165 13.1253 7.9165 12.9265 7.9165 12.5287V7.47095Z"
                          stroke="#344054"
                          stroke-width="1.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_346_7849">
                          <rect width="20" height="20" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </Box>
                  <Typography
                    sx={{
                      color: ' var(--Gray-700, #344054)',
                      /* Text lg/Semibold */
                      fontFamily: 'Inter',
                      fontSize: '18px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '28px',
                    }}
                  >
                    Get Started for free
                  </Typography>
                </Button>
              )}
            </Box>
            </Box>
            <Box sx={{
              alignSelf: 'center',
              marginY: {xs: '64px', md: '0px'},
              height: '100%',
            }}>
            <HeroImage width={
              matches ? '293px' : 'auto'
            }
            height={
              matches ? '302px' : 'auto'
            }
            />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FirstSection;
