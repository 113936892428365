import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import CustomText from '../CustomText';
import Label from '../Label';

const TextAreaField = ({ label, onChange, name }) => {
  return (
    <FormControl>
      <Label label={label} />
      <TextField
        name={name}
        variant="outlined"
        placeholder="Leave us a message..."
        multiline
        minRows={3}
        maxRows={3}
        onChange={onChange}
        sx={{
          '& .MuiInputBase-root': {
            padding: '12px 14px',
            borderRadius: '8px',
          },
          '& .MuiInputBase-input': {
            height: '110px',
            color: '#667085',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '24px',
            '&::placeholder': {
              opacity: 1,
            },
          },
        }}
      />
    </FormControl>
  );
};

export default TextAreaField;