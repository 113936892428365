import { Box } from '@mui/system';
// import Footer from 'store/Footer/index';
import Footer from 'store/Footer';
import Header from 'store/Header';
import FirstSection from './FirstSection/FirstSection';
import SectionTwo from './SectionTwo';
import SectionThree from './SectionThree';
import SectionFour from './SectionFour';
import SectionFive from './SectionFive';
import SectionSix from './SectionSix';

function Solution() {
  return (
    <Box
    sx = {{
      width: "100wv",
      overflow: "hidden"
    }}> 
      {/* every one has to have width: 80% and margin auto or add class name contentBox */}

      <Box
        sx={{
          width: '100%',
          // height: '1116px',
          // backgroundImage:
          //   'linear-gradient(38.02deg, #02096E 8%, #030755 27%, #040433 58%, #05031E 83%, #060317 100%)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
        }}
      >
        <Header />

      </Box>
      <FirstSection />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
      {/* <Footer /> */}
    </Box>
  );
}

export default Solution;
