import { Box } from '@mui/material';
import Header from 'store/Header';

import LeftSection from './LeftSection';
import RightSection from './RightSection';

import Matches from 'store/matches';

const ContactUs = () => {
  const matches = Matches();
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
      }}
    >
      <Header />
      <Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}>
          {!matches && <LeftSection />}
          <RightSection />
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUs;
