import { Box, Typography } from '@mui/material';

const SectionThree = () => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: { xs: '100%', md: '1280px' },
          padding: { xs: '0px 16px', md: '0px 32px' },
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '32px',
          marginX: 'auto',
          boxSizing: 'border-box',
        }}
      >
        <Box
          sx={{
            borderRadius: '16px',
            background: 'var(--Gray-50, #F9FAFB)',
            display: 'flex',
            height: 'auto',
            padding: { xs: '40px 24px', md: '60.5px 107.5px' },
            justifyContent: 'space-between',
            alignItems: 'center',
            alignSelf: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', md: '1001px' },
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '32px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: { xs: '32px', md: '24px' },
              }}
            >
              <Typography
                sx={{
                  color: 'var(--Gray-900, #101828)',
                  fontFamily: 'Inter',
                  fontSize: '30px',
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: '38px',
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                Powered by Azure
              </Typography>
              <Typography
                sx={{
                  width: '100%',
                  color: 'var(--Gray-600, #475467)',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontSize: { xs: '18px', md: '20px' },
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: { xs: '28px', md: '30px' },
                }}
              >
                Our solutions are built on the strength of Azure, including
                Azure OpenAI for cutting-edge AI capabilities, Azure Cosmos DB
                for global distribution and horizontal scaling, and more. This
                partnership ensures that our platforms are not only powerful and
                reliable but also at the forefront of technological innovation.
              </Typography>
            </Box>
            {/* <Button
              variant="contained"
              color="secondary"
              sx={{
                borderRadius: '8px',
                width: { xs: '100%', md: 'auto' },
                // background: 'var(--Primary-Buttons, #4F9CF9)',
                'background:hover': 'red',
                boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                display: 'flex',
                height: '52px',
                padding: { xs: '12px 20px', md: '16px 28px' },
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                textTransform: 'none',
              }}
            >
              <Typography
                sx={{
                  color: 'var(--Base-White, #FFF)',
                  fontFamily: 'Inter',
                  fontSize: { xs: '16px', md: '18px' },
                  fontStyle: 'normal',
                  fontWeight: 600,
                  lineHeight: { xs: '24px', md: '28px' },
                }}
              >
                Learn More
              </Typography>
            </Button> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SectionThree;
