import { Box, Input, InputBase, TextField } from '@mui/material';
import React from 'react';

import { ReactComponent as SearchIcon } from 'store/images/Blog/searchIcon.svg';

const SearchBar = ({ onSearch }) => {

  const handleInputChange = (event) => {
    onSearch(event.target.value); 
  };

  return (
    <Box
      sx={{
        padding: '12px 14px',
        alignSelf: 'center',
        background: 'white',
        maxWidth: 'fit-content',
        borderRadius: '8px',
        boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
        marginTop: '40px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'flex-end', maxHeight: '24px' }}>
        <SearchIcon style={{ margin: '0 8px 4px 0', alignSelf: 'center' }} />
        <InputBase
          onChange={handleInputChange}
          sx={{
            width: '320px',
            height: '24px',
            fontFamily: 'Inter',
            fontStyle: 'normal',
            fontSize: '16px',
            fontWeight: '400',
            lineHeight: '24px',
            color: '#667085',
            '& .MuiInputBase-input': {
              '&::placeholder': {
                opacity: 1,
              },
            },
          }}
          placeholder="Search"
        />
      </Box>
    </Box>
  );
};

export default SearchBar;
