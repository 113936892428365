import { Box, InputBase } from '@mui/material';
import Label from '../Label';

const InputField = ({ label, placeholder,onChange , name }) => {
  return (
    <Box>
      <Label label={label} />
      <Box
        sx={{
          padding: '12px 14px',
          alignSelf: 'center',
          background: 'white',
          borderRadius: '8px',
          border: '1px solid var(--Gray-300, #D0D5DD)',
          boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
          flex: 1,
        }}
      >
        <Box
          sx={{ display: 'flex', maxHeight: '24px', width: '100%' }}
        >
          <InputBase
            name={name}
            sx={{
              flex: '1',
              height: '24px',
              fontFamily: 'Inter',
              fontStyle: 'normal',
              fontSize: { xs: '14px', md: '16px'},
              fontWeight: '400',
              lineHeight: { xs: '20px', md: '24px'},
              color: '#667085',
              '& .MuiInputBase-input': {
                '&::placeholder': {
                  opacity: 1,
                },
              },
            }}
            placeholder={placeholder}
            onChange={onChange}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default InputField;
