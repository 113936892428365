import { Typography } from '@mui/material'
import React from 'react'

const CustomText = ({text, textStyles}) => {
  return (
    <Typography
    sx={{
      alignSelf: 'stretch',
      fontWeight: '600',
      fontSize: '36px',
      lineHeight: '44px',
      textAlign: 'Center',
      letterSpacing: '-0.72px',
      color: '#101828',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      ...textStyles
    }}
    >
      {text}
    </Typography>
  )
}

export default CustomText;