import { Box, Typography, Button } from '@mui/material';

function ForthSection() {
  return (
    <>
      {/* every one has to have width: 80% and margin auto or add class name contentBox */}

      <Box
        sx={{
          width: '100%',
        }}
      >
        <Box
          className="contentBox"
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'center',
            alignItems: 'center',
            gap: { xs: '24px', md: '30px' },
            py: { xs: '0', md: '90px' },
          }}
        >
          <Box
            sx={{
              boxSizing: 'border-box',
              maxWidth: '390px',
              height: { xs: 'auto', md: '375px'},
              backgroundColor: '#F9FAFB',
              p: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '64px'
            }}
          >
            <Box>
              <svg
                width="48"
                height="48"
                viewBox="0 0 48 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="48" height="48" rx="10" fill="#4F9CF9" />
                <path
                  d="M18.0944 23.2288C18.0322 22.8282 18 22.4179 18 22C18 17.5817 21.6052 14 26.0526 14C30.4999 14 34.1052 17.5817 34.1052 22C34.1052 22.9981 33.9213 23.9535 33.5852 24.8345C33.5154 25.0175 33.4804 25.109 33.4646 25.1804C33.4489 25.2512 33.4428 25.301 33.4411 25.3735C33.4394 25.4466 33.4493 25.5272 33.4692 25.6883L33.8717 28.9585C33.9153 29.3125 33.9371 29.4895 33.8782 29.6182C33.8266 29.731 33.735 29.8205 33.6211 29.8695C33.4911 29.9254 33.3146 29.8995 32.9617 29.8478L29.7765 29.3809C29.6101 29.3565 29.527 29.3443 29.4512 29.3448C29.3763 29.3452 29.3245 29.3507 29.2511 29.3661C29.177 29.3817 29.0823 29.4172 28.893 29.4881C28.0097 29.819 27.0524 30 26.0526 30C25.6344 30 25.2237 29.9683 24.8227 29.9073M19.6316 34C22.5965 34 25 31.5376 25 28.5C25 25.4624 22.5965 23 19.6316 23C16.6667 23 14.2632 25.4624 14.2632 28.5C14.2632 29.1106 14.3603 29.6979 14.5395 30.2467C14.6153 30.4787 14.6532 30.5947 14.6657 30.6739C14.6786 30.7567 14.6809 30.8031 14.6761 30.8867C14.6714 30.9668 14.6514 31.0573 14.6113 31.2383L14 34L16.9948 33.591C17.1583 33.5687 17.24 33.5575 17.3114 33.558C17.3865 33.5585 17.4264 33.5626 17.5001 33.5773C17.5701 33.5912 17.6742 33.6279 17.8823 33.7014C18.4306 33.8949 19.0191 34 19.6316 34Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: { xs: '4px', md: '15px' },
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '18px', md: '20px' },
                  lineHeight: { xs: '28px', md: '30px' },
                  textAlign: 'left',
                  color: '#101828',
                }}
              >
                Our Mission
              </Typography>

              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#475467',
                }}
              >
                At Ezaix, we're committed to enabling businesses to unlock their
                full potential through the power of AI, Automation, and Machine
                Learning.
              </Typography>

              {/* <Button
                variant="text"
                color="inherit"
                sx={{ height: '44px', gap: '16px' }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#4F9CF9',
                  }}
                >
                  View More
                </Typography>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.16602 6.99984H12.8327M12.8327 6.99984L6.99935 1.1665M12.8327 6.99984L6.99935 12.8332"
                    stroke="#4F9CF9"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button> */}
            </Box>
          </Box>

          <Box
            sx={{
              boxSizing: 'border-box',
              maxWidth: '390px',
              height: { xs: 'auto', md: '375px'},
              backgroundColor: '#F9FAFB',
              p: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '64px'
            }}
          >
            <Box>
              <svg
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.333374"
                  width="48"
                  height="48"
                  rx="10"
                  fill="#4F9CF9"
                />
                <path
                  d="M23.3334 15H20.1334C18.4532 15 17.6131 15 16.9714 15.327C16.4069 15.6146 15.948 16.0735 15.6604 16.638C15.3334 17.2798 15.3334 18.1198 15.3334 19.8V28.2C15.3334 29.8802 15.3334 30.7202 15.6604 31.362C15.948 31.9265 16.4069 32.3854 16.9714 32.673C17.6131 33 18.4532 33 20.1334 33H28.5334C30.2135 33 31.0536 33 31.6953 32.673C32.2598 32.3854 32.7188 31.9265 33.0064 31.362C33.3334 30.7202 33.3334 29.8802 33.3334 28.2V25M24.3334 20H28.3334V24M27.8334 15.5V14M31.7727 16.5607L32.8334 15.5M32.8436 20.5H34.3436M15.3334 25.3471C15.9853 25.4478 16.6532 25.5 17.3334 25.5C21.7197 25.5 25.5987 23.3276 27.9531 20"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: { xs: '4px', md: '15px' },
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '18px', md: '20px' },
                  lineHeight: { xs: '28px', md: '30px' },
                  textAlign: 'left',
                  color: '#101828',
                }}
              >
                Our Expertise
              </Typography>

              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#475467',
                }}
              >
                AI, Automation, and Machine Learning experts delivering customized, cutting-edge solutions to revolutionize business operations and drive growth.
              </Typography>

              {/* <Button
                variant="text"
                color="inherit"
                sx={{ height: '44px', gap: '16px' }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#4F9CF9',
                  }}
                >
                  View More
                </Typography>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.16602 6.99984H12.8327M12.8327 6.99984L6.99935 1.1665M12.8327 6.99984L6.99935 12.8332"
                    stroke="#4F9CF9"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button> */}
            </Box>
          </Box>

          <Box
            sx={{
              boxSizing: 'border-box',
              maxWidth: '390px',
              height: { xs: 'auto', md: '375px'},
              backgroundColor: '#F9FAFB',
              p: '20px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '64px'
            }}
          >
            <Box>
              <svg
                width="49"
                height="48"
                viewBox="0 0 49 48"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.666504"
                  width="48"
                  height="48"
                  rx="10"
                  fill="#4F9CF9"
                />
                <path
                  d="M21.6661 26C21.6661 26 22.9786 27.5 25.1661 27.5C27.3536 27.5 28.6661 26 28.6661 26M27.9161 21H27.9261M22.4161 21H22.4261M25.1661 32C29.8605 32 33.6661 28.1944 33.6661 23.5C33.6661 18.8056 29.8605 15 25.1661 15C20.4717 15 16.6661 18.8056 16.6661 23.5C16.6661 24.45 16.822 25.3636 17.1095 26.2166C17.2177 26.5376 17.2718 26.6981 17.2816 26.8214C17.2912 26.9432 17.2839 27.0286 17.2538 27.1469C17.2233 27.2668 17.1559 27.3915 17.0212 27.6408L15.3856 30.6684C15.1523 31.1002 15.0356 31.3161 15.0617 31.4828C15.0844 31.6279 15.1699 31.7557 15.2953 31.8322C15.4393 31.9201 15.6834 31.8948 16.1717 31.8444L21.2927 31.315C21.4478 31.299 21.5253 31.291 21.596 31.2937C21.6655 31.2963 21.7146 31.3029 21.7824 31.3185C21.8513 31.3344 21.9379 31.3678 22.1113 31.4345C23.0593 31.7998 24.0894 32 25.1661 32ZM28.4161 21C28.4161 21.2761 28.1923 21.5 27.9161 21.5C27.64 21.5 27.4161 21.2761 27.4161 21C27.4161 20.7239 27.64 20.5 27.9161 20.5C28.1923 20.5 28.4161 20.7239 28.4161 21ZM22.9161 21C22.9161 21.2761 22.6923 21.5 22.4161 21.5C22.14 21.5 21.9161 21.2761 21.9161 21C21.9161 20.7239 22.14 20.5 22.4161 20.5C22.6923 20.5 22.9161 20.7239 22.9161 21Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: { xs: '4px', md: '15px' },
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '18px', md: '20px' },
                  lineHeight: { xs: '28px', md: '30px' },
                  textAlign: 'left',
                  color: '#101828',
                }}
              >
                Our Team
              </Typography>

              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'left',
                  color: '#475467',
                }}
              >
                Our team comprises highly skilled technical experts led by a seasoned leadership team with over 40 years of combined experience. With their deep knowledge and strong guidance, Ezaix consistently deliver innovative solutions that exceed our clients expectations
              </Typography>

              {/* <Button
                variant="text"
                color="inherit"
                sx={{ height: '44px', gap: '16px' }}
              >
                <Typography
                  sx={{
                    fontWeight: '600',
                    fontSize: '16px',
                    lineHeight: '24px',
                    color: '#4F9CF9',
                  }}
                >
                  View More
                </Typography>
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.16602 6.99984H12.8327M12.8327 6.99984L6.99935 1.1665M12.8327 6.99984L6.99935 12.8332"
                    stroke="#4F9CF9"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </Button> */}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default ForthSection;
