import { Box, Button } from '@mui/material';
import CustomText from 'store/General/CustomText';
import AccordionExpand from './accordion';

import { FAQS } from 'constants/faqs';
import { ReactComponent as AvatarGroup } from 'store/images/avatar-group.svg';

const FaqSection = () => {
  const handleNavigation = (link) => {
    window.location.href = link;
  };

  return (
    <Box
      sx={{
        px: { xs: '16px', md: '32px' },
        width: { xs: 'auto', md: '1280px' },
        display: 'flex',
        flexDirection: 'column',
        gap: { xs: '48px', md: '64px' },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
        <CustomText
          text="Frequently asked questions"
          textStyles={{
            color: '#101828',
            fontSize: { xs: '32px', md: '36px' },
            lineHeight: { xs: '38px', md: '44px' },
          }}
        />
        <CustomText
          text="Everything you need to know about the Ezaix and services we offer."
          textStyles={{
            color: '#475467',
            fontSize: { xs: '18px', md: '20px' },
            fontWeight: '400',
            lineHeight: { xs: '28px', md: '32px' },
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: 'auto', md: '768px' },
          alignSelf: 'center',
        }}
        gap="32px"
      >
        {FAQS.map((faq, index) => (
          <AccordionExpand
            key={index}
            index={index}
            title={faq.title}
            desc={faq.desc}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: { xs: '32px 20px', md: '32px' },
          alignItems: 'center',
          background: '#F9FAFB',
          borderRadius: '16px',
          gap: { xs: '24px', md: '32px' },
        }}
      >
        <AvatarGroup />
        <Box>
          <CustomText
            text="Still have questions?"
            textStyles={{
              color: '#101828',
              fontSize: '20px',
              lineHeight: '30px',
              marginBottom: '8px',
            }}
          />
          <CustomText
            text="Can’t find the answer you’re looking for? Please chat to our friendly team."
            textStyles={{
              color: '#475467',
              fontSize: { xs: '16px', md: '18px'},
              fontWeight: '400',
              lineHeight: '28px',
            }}
          />
        </Box>
        <Button
          onClick={() => handleNavigation('/contact')}
          variant="contained"
          color="secondary"
          sx={{
            alignSelf: 'center',
            height: '48px',
            borderRadius: '8px',
            border: '1px solid #4F9CF9',
            padding: '12px 20px',
            textTransform: 'none',
          }}
        >
          <CustomText
            text="Get in Touch"
            textStyles={{
              fontWeight: '600',
              fontSize: '16px',
              lineHeight: '24px',
              color: 'white',
              padding: '0',
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

export default FaqSection;
