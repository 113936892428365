import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Home from './component/Home';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box, } from '@mui/material';
import AboutUs from 'component/AboutUs';
import Blogs from 'component/Blogs';
import Footer from 'store/Footer';
import BlogDetail from 'component/BlogsDetail';
import Partner from 'component/Partner';
import Solution from 'component/Solution';
import ContactUs from 'component/ContactUs';
import PrivacyPolicy from 'component/PrivacyPage';

const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#4F9CF9',
    },
    tertiary: {
      main: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: [
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          // overflowY:"scroll",
          // overflowX:"hidden",
        }}
      >
        {/* <Header/> */}
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/partner" element={<Partner/>} />
            <Route path="/solution" element={<Solution/>} />
            <Route path="/aboutUs" element={<AboutUs />} />
            <Route path="/blogs" element={<Blogs />} />
            <Route path="/blogs/:id" element={<BlogDetail />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          </Routes>
        <Footer />
        </Router>
      </Box>
    </ThemeProvider>
  );
}

export default App;
