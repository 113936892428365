import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { ReactComponent as DevicesImages } from 'store/images/devices.svg';
import { ReactComponent as IPhoneImg } from 'store/images/iphone.svg';

function FifthSection() {
  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down('md'));

  const handleNavigation = (link) => {
    window.open(link, '_blank');
  }
  return (
    <>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItem: 'center',
          justifyContentL: 'center',
          py: '64px',
          gap: { xs: '48px', md: '64px' },
        }}
      >
        <Box
          className="contentBox"
          sx={{
            width: { xs: 'auto', md: '768px' },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '16px',
            backgroundColor: 'white',
          }}
        >
          <Box
            sx={{
              backgroundColor: '#F9F5FF',
              borderRadius: '16px',
              padding: '4px 12px',
            }}
          >
            <Typography
              sx={{
                fontWeight: '500',
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'Center',
                color: '#4F9CF9',
              }}
            >
              Our Services
            </Typography>
          </Box>

          <Typography
            sx={{
              fontWeight: '600',
              fontSize: { xs: '30px', md: '36px' },
              lineHeight: '44px',
              textAlign: 'Center',
              color: '#101828',
            }}
          >
            Custom Innovation for Every Enterprise
          </Typography>

          <Typography
            sx={{
              fontWeight: '400',
              fontSize: { xs: '18px', md: '20px' },
              lineHeight: '30px',
              textAlign: 'Center',
              color: '#475467',
            }}
          >
            Our approach starts with a deep understanding of your enterprise's
            specific needs. We develop technology solutions that integrate
            seamlessly with your existing processes, enhancing efficiency,
            scalability, and innovation.
          </Typography>
        </Box>

        <Box
          className="contentBox"
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <DevicesImages height={{ xs: '235px', md: 'auto' }} />
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Button
            onClick={() =>
              handleNavigation(
                'https://azuremarketplace.microsoft.com/en-us/marketplace/apps/ezaixinc1714844874000.teamscoreiqms?tab=Overview'
              )
            }
            sx={{
              display: 'flex',
              padding: { xs: '12px 20px', md: '16px 28px' },
              justifyContent: 'center',
              alignItems: 'center',
              gap: { xs: '8px', md: '12px' },
              borderRadius: '8px',
              background: 'var(--Primary-Buttons, #4F9CF9)',
              width: 'auto',
              boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
              textTransform: 'none',
              '&:hover': {
                background: '#3b7fd1',
              },
            }}
          >
            <Typography
              sx={{
                color: 'var(--Base-White, #FFF)',
                fontFamily: 'Inter',
                fontSize: { xs: '16px', md: '18px' },
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: { xs: '24px', md: '28px' },
                whiteSpace: 'nowrap',
              }}
            >
              Purchase TeamsCoreIQ
            </Typography>
          </Button>
        </Box>
        <Box
          className="contentBox"
          sx={{
            width: { xs: 'auto', md: '1216px' },
            display: 'flex',
            flexDirection: { xs: 'column', md: 'row' },
            alignContent: 'center',
            justifyContent: 'center',
            gap: { xs: '40px', md: '32px' },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4.5"
                y="4"
                width="40"
                height="40"
                rx="20"
                fill="#A7CEFC"
                fill-opacity="0.4"
              />
              <rect
                x="4.5"
                y="4"
                width="40"
                height="40"
                rx="20"
                stroke="#A7CEFC"
                stroke-opacity="0.2"
                stroke-width="8"
              />
              <g clip-path="url(#clip0_159_8262)">
                <path
                  d="M20.7533 23.8368C20.71 23.5572 20.6875 23.2708 20.6875 22.9792C20.6875 19.8956 23.2036 17.3959 26.3075 17.3959C29.4114 17.3959 31.9276 19.8956 31.9276 22.9792C31.9276 23.6758 31.7992 24.3426 31.5646 24.9575C31.5159 25.0852 31.4915 25.149 31.4805 25.1989C31.4695 25.2483 31.4653 25.283 31.4641 25.3336C31.4629 25.3846 31.4698 25.4409 31.4837 25.5533L31.7646 27.8357C31.7951 28.0827 31.8103 28.2063 31.7692 28.2961C31.7332 28.3748 31.6692 28.4373 31.5897 28.4715C31.499 28.5105 31.3758 28.4924 31.1295 28.4563L28.9065 28.1305C28.7904 28.1135 28.7323 28.1049 28.6795 28.1052C28.6272 28.1055 28.591 28.1094 28.5398 28.1202C28.4881 28.131 28.422 28.1558 28.2899 28.2053C27.6734 28.4362 27.0053 28.5625 26.3075 28.5625C26.0157 28.5625 25.729 28.5404 25.4491 28.4978M21.8262 31.3542C23.8954 31.3542 25.5729 29.6356 25.5729 27.5157C25.5729 25.3957 23.8954 23.6771 21.8262 23.6771C19.7569 23.6771 18.0795 25.3957 18.0795 27.5157C18.0795 27.9418 18.1473 28.3517 18.2724 28.7347C18.3253 28.8966 18.3517 28.9776 18.3604 29.0329C18.3694 29.0906 18.371 29.1231 18.3677 29.1814C18.3644 29.2373 18.3504 29.3004 18.3225 29.4268L17.8958 31.3542L19.9859 31.0688C20.1 31.0532 20.1571 31.0454 20.2069 31.0457C20.2593 31.0461 20.2872 31.0489 20.3386 31.0592C20.3874 31.0689 20.4601 31.0945 20.6053 31.1458C20.988 31.2809 21.3987 31.3542 21.8262 31.3542Z"
                  stroke="#4F9CF9"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_159_8262">
                  <rect
                    width="16.75"
                    height="16.75"
                    fill="white"
                    transform="translate(16.5 16)"
                  />
                </clipPath>
              </defs>
            </svg>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '18px', md: '20px' },
                  lineHeight: { xs: '28px', md: '30px' },
                  textAlign: 'center',
                  color: '#101828',
                }}
              >
                Tailored Technological Integration
              </Typography>

              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: '#475467',
                }}
              >
                Whether you have a team of 2 or 200, our shared team inboxes
                keep everyone on the same page and in the loop.
              </Typography>
            </Box>

            {/* <Button
              variant="text"
              color="inherit"
              sx={{ height: '44px', gap: '16px' }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#4F9CF9',
                }}
              >
                View More
              </Typography>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.16602 6.99984H12.8327M12.8327 6.99984L6.99935 1.1665M12.8327 6.99984L6.99935 12.8332"
                  stroke="#4F9CF9"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button> */}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4.5"
                y="4"
                width="40"
                height="40"
                rx="20"
                fill="#A7CEFC"
                fill-opacity="0.4"
              />
              <rect
                x="4.5"
                y="4"
                width="40"
                height="40"
                rx="20"
                stroke="#A7CEFC"
                stroke-opacity="0.2"
                stroke-width="8"
              />
              <path
                d="M25.8334 15.6666L18.4112 24.5732C18.1206 24.922 17.9752 25.0964 17.973 25.2437C17.9711 25.3717 18.0281 25.4935 18.1277 25.574C18.2423 25.6666 18.4693 25.6666 18.9234 25.6666H25L24.1667 32.3333L31.5888 23.4267C31.8795 23.0779 32.0248 22.9035 32.027 22.7562C32.029 22.6282 31.9719 22.5064 31.8723 22.4259C31.7577 22.3333 31.5307 22.3333 31.0767 22.3333H25L25.8334 15.6666Z"
                stroke="#4F9CF9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '18px', md: '20px' },
                  lineHeight: { xs: '28px', md: '30px' },
                  textAlign: 'center',
                  color: '#101828',
                }}
              >
                Advanced Analytics& Data Insights:
              </Typography>

              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: '#475467',
                }}
              >
                Leverage powerful data analytics to enhancedecision-making,
                improve customer satisfaction, and drive growth.
              </Typography>
            </Box>

            {/* <Button
              variant="text"
              color="inherit"
              sx={{ height: '44px', gap: '16px' }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#4F9CF9',
                }}
              >
                View More
              </Typography>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.16602 6.99984H12.8327M12.8327 6.99984L6.99935 1.1665M12.8327 6.99984L6.99935 12.8332"
                  stroke="#4F9CF9"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button> */}
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '20px',
            }}
          >
            <svg
              width="49"
              height="48"
              viewBox="0 0 49 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="4.5"
                y="4"
                width="40"
                height="40"
                rx="20"
                fill="#A7CEFC"
                fill-opacity="0.4"
              />
              <rect
                x="4.5"
                y="4"
                width="40"
                height="40"
                rx="20"
                stroke="#A7CEFC"
                stroke-opacity="0.2"
                stroke-width="8"
              />
              <path
                d="M24.1667 16.5H21.5C20.0999 16.5 19.3998 16.5 18.865 16.7724C18.3946 17.0121 18.0122 17.3946 17.7725 17.865C17.5 18.3998 17.5 19.0998 17.5 20.5V27.5C17.5 28.9001 17.5 29.6002 17.7725 30.1349C18.0122 30.6053 18.3946 30.9878 18.865 31.2275C19.3998 31.5 20.0999 31.5 21.5 31.5H28.5C29.9001 31.5 30.6002 31.5 31.135 31.2275C31.6054 30.9878 31.9878 30.6053 32.2275 30.1349C32.5 29.6002 32.5 28.9001 32.5 27.5V24.8333M25 20.6666H28.3333V24M27.9167 16.9166V15.6666M31.1995 17.8005L32.0833 16.9166M32.0919 21.0833H33.3419M17.5 25.1225C18.0433 25.2064 18.5999 25.25 19.1667 25.25C22.822 25.25 26.0544 23.4396 28.0164 20.6666"
                stroke="#4F9CF9"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '4px',
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: { xs: '18px', md: '20px' },
                  lineHeight: { xs: '28px', md: '30px' },
                  textAlign: 'center',
                  color: '#101828',
                }}
              >
                Manage your team with reports
              </Typography>

              <Typography
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                  lineHeight: '24px',
                  textAlign: 'center',
                  color: '#475467',
                }}
              >
                Measure what matters with Untitled’s easy-to-use reports. You
                can filter, export, and drilldown on the data in a couple
                clicks.
              </Typography>
            </Box>
            {/* <Button
              variant="text"
              color="inherit"
              sx={{ height: '44px', gap: '16px' }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: '16px',
                  lineHeight: '24px',
                  color: '#4F9CF9',
                }}
              >
                View More
              </Typography>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.16602 6.99984H12.8327M12.8327 6.99984L6.99935 1.1665M12.8327 6.99984L6.99935 12.8332"
                  stroke="#4F9CF9"
                  stroke-width="1.66667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </Button> */}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default FifthSection;
